import { FC } from "react";
import { Layout } from "components/Layout";
import { NextSeo } from "next-seo";

import { WalletStep } from "./steps/WalletStep";
import config from "config";
import { Stepper } from "./Stepper";
import { useSubmitPageData } from "./SubmitPageDataProvider";
import Link from "next/link";
import { Logo } from "components/Logo";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";

const SubmitPageModule: FC = ({ children }) => {
  const { wallet } = useSubmitPageData();

  return (
    <Layout>
      <NextSeo
        title="Floppy Labs - Submit your NFT project"
        description="Submit your NFT project"
      />

      <div className="absolute top-0 left-0 bottom-0 right-0 flex flex-col">
        <div className="bg-base-200 py-2">
          <div className="navbar mx-auto max-w-6xl">
            <div className="flex-1">
              <Link href={config.HOME_URL}>
                <a>
                  <Logo />
                </a>
              </Link>
            </div>
            {!!wallet?.publicKey && (
              <div>
                <WalletMultiButton className="btn" />
              </div>
            )}
          </div>
        </div>

        {!wallet?.publicKey && <WalletStep />}
        {!!wallet?.publicKey && (
          <div className="flex flex-col">
            <div className="mx-auto w-full max-w-7xl">
              <Stepper />
            </div>
            {children}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default SubmitPageModule;
