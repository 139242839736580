import { WLStakingService } from "services/WLStakingService";
import { CreationData, ProjectMetadata, ConfirmationData } from "types";
import * as anchor from "@project-serum/anchor";
import { AnchorWallet } from "@solana/wallet-adapter-react";
import { Connection, PublicKey } from "@solana/web3.js";
import { ApiService } from "services/ApiService";
import { getMint } from "@solana/spl-token";

type ConstructorParams = {
  program?: anchor.Program;
  wallet?: AnchorWallet;
  connection: Connection;
};

class StakingProjectCreator {
  creationData?: CreationData;
  logger: any;
  program?: anchor.Program;
  wallet?: AnchorWallet;
  connection: Connection;
  stakingService: WLStakingService | undefined;
  apiService: ApiService;
  metadata?: ProjectMetadata;
  stakingKey: string | undefined;

  constructor(params: ConstructorParams) {
    this.logger = console;
    this.logger.log("Constructing with params", params);

    this.program = params.program;
    this.wallet = params.wallet;
    this.connection = params.connection;
    this.apiService = new ApiService();
  }

  setCreationData(data: CreationData) {
    this.creationData = data;
  }

  setMetadata(data: ProjectMetadata) {
    this.metadata = data;
  }

  prepare(): StakingProjectCreator {
    if (!this.program || !this.wallet || !this.metadata) {
      throw new Error("Service not ready, missing program/wallet/metadata");
    }

    const stakingKey = WLStakingService.generateStakingKey();

    this.logger.log(`Staking key, ${stakingKey}`);

    this.stakingKey = stakingKey.toString();

    this.stakingService = new WLStakingService({
      program: this.program,
      wallet: this.wallet,
      connection: this.connection,
      stakingKey: stakingKey.toBase58(),
      metadata: this.metadata,
    });

    return this;
  }

  async validate() {
    if (!this.creationData) {
      return;
    }
    return this.apiService.validateProject({
      slug: this.creationData.projectSlug,
      name: this.creationData.projectName,
      imageUrl: this.creationData.imageUrl,
      description: this.creationData.projectDescription,
      stakingKey: this.stakingKey,
      metadata: this.metadata,
    });
  }

  async createOnDatabase() {
    if (!this.creationData) {
      return;
    }
    return this.apiService.createProject({
      slug: this.creationData.projectSlug,
      name: this.creationData.projectName,
      imageUrl: this.creationData.imageUrl,
      description: this.creationData.projectDescription,
      stakingKey: this.stakingKey,
      metadata: this.metadata,
    });
  }

  async create(): Promise<ConfirmationData> {
    if (!this.stakingService || !this.creationData) {
      throw new Error("Not ready for creation, call prepare before");
    }

    const timestamp = Math.round(
      new Date(this.creationData.goLiveDate).getTime() / 1000
    );

    const dailyRewards = this.creationData.dailyRewards;

    const mintPublicKey = new PublicKey(this.creationData.tokenMintAddress);
    const mintInfo = await getMint(this.connection, mintPublicKey);

    if (!mintInfo) {
      throw Error("Not able to get mintInfo");
    }

    const chainDailyRewards = dailyRewards * Math.pow(10, mintInfo?.decimals);

    await this.validate();

    const { stakingKey, rewardsAddress } =
      await this.stakingService.createStakingProject({
        timestamp,
        dailyRewards: chainDailyRewards,
        tokenMintAddress: this.creationData.tokenMintAddress,
      });

    await this.createOnDatabase();

    return {
      projectName: this.creationData.projectName,
      projectSlug: this.creationData.projectSlug,
      projectDescription: this.creationData.projectDescription,
      mintAddress: this.creationData.tokenMintAddress,
      dailyRewards: dailyRewards,
      goLiveDate: timestamp,
      rewardsAccount: rewardsAddress,
      stakingKey,
    };
  }

  getDebugInfo() {
    return {
      publicKey: this.wallet?.publicKey?.toString(),
      stakingKey: this.stakingKey?.toString(),
      programId: this.program?.programId?.toString(),
    };
  }
}

export { StakingProjectCreator };
