import axios, { AxiosInstance } from "axios";

class ApiService {
  axios: AxiosInstance;

  constructor() {
    this.axios = axios.create({
      baseURL: "/api/",
    });
  }

  isBrowser() {
    return typeof window !== "undefined";
  }

  async createProject(data: any) {
    return this.axios.post("create-project", data);
  }

  async validateProject(data: any) {
    return this.axios.post("validate", data);
  }

  async validateSlug(slug: string) {
    return this.axios.post("validate-slug", { slug });
  }

  async getToken() {
    return this.axios.post("get-token", undefined);
  }

  async getStakedInfo(mints: string[]) {
    return this.axios.post("staked-info", mints);
  }

  async getTotalStaked() {
    return this.axios.get("totals_perf");
  }

  async revalidate(slug: string) {
    return this.axios.get("revalidate", { params: { slug: slug } });
  }

  async updateHashlist(stakingKey: any, signature: any, hashlist: any) {
    return this.axios.post(
      "update-hashlist",
      {
        stakingKey: stakingKey,
        signature: signature,
        hashlist: hashlist,
      },
      {
        validateStatus: function (status) {
          return status < 500; // Reject only if the status code is greater than or equal to 500
        },
      }
    );
  }

  async verify(
    discordUsername: any,
    discordId: any,
    signature: any,
    publicKey: any
  ) {
    return this.axios.post(
      "verify",
      {
        discordName: discordUsername,
        discordId: discordId,
        signature: signature,
        publicKey: publicKey.toString(),
      },
      {
        validateStatus: function (status) {
          return status < 500; // Reject only if the status code is greater than or equal to 500
        },
      }
    );
  }

  async getStakerDiscordUser(publicKey: any) {
    return this.axios.post("discord-user", {
      publicKey: publicKey.toString(),
    });
  }

  // External services

  async getDiscordData(accessToken: string) {
    return this.axios.get("https://discord.com/api/users/@me", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
}

const ApiServiceInstance = new ApiService();

export { ApiService, ApiServiceInstance };
