import { AppProps } from "next/app";
import { WalletProvider } from "modules/WalletProvider";
import { NextSeo } from "next-seo";
import { WLProjectProvider } from "./index";
import config from "config";

const WLPageProvider = ({ Component, pageProps }: AppProps) => {
  return (
    <>
      <NextSeo
        title={pageProps.projectName}
        description={pageProps.projectDescription}
        canonical={pageProps.projectUrl}
        openGraph={{
          type: "website",
          images: [
            {
              url: pageProps.projectImageUrl,
              alt: pageProps.projectName,
              type: "image/png",
            },
          ],
        }}
        twitter={{
          // TODO replace this with the project Twitter account.
          site: "@FloppyLabs",
        }}
      />
      <WalletProvider>
        <WLProjectProvider
          metadata={pageProps.projectMetadata}
          stakingKey={pageProps.stakingKey}
          slug={pageProps.projectSlug}
        >
          <Component {...pageProps} />
        </WLProjectProvider>
      </WalletProvider>
    </>
  );
};

export default WLPageProvider;
