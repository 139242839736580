import { FC, useCallback, useMemo, useState } from "react";
import { PublicKey } from "@solana/web3.js";
import { useAsync, useAsyncFn } from "react-use";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import * as anchor from "@project-serum/anchor";
import config from "config";
import { useProgram } from "modules/useProgram";
import { useWallet } from "@solana/wallet-adapter-react";
import { fetchProjectStakedNFTs } from "services/fetchStakedNFTs";
import { fetchWalletNFTs } from "services/fetchWalletNFTs";

import { WLStakingService } from "services/WLStakingService";
import toast from "react-hot-toast";
import * as Sentry from "@sentry/nextjs";
import { TimeoutToast } from "utils/TimeoutToast";
import { getConnectionConfig } from "utils/getConnectionConfig";

import WLContext from "./WLContext";
import { parseSendTransactionError } from "utils/parseSendTransactionError";
import bs58 from "bs58";
import { sign } from "tweetnacl";

import { ApiService } from "services/ApiService";

type Props = {
  metadata: any;
  stakingKey: string;
  slug: string;
};

const WLProjectProvider: FC<Props> = ({
  children,
  metadata,
  stakingKey,
  slug,
}) => {
  const connection = useMemo(
    () =>
      new anchor.web3.Connection(config.SOLANA_ENDPOINT, getConnectionConfig()),
    []
  );
  const [stakingAll, setStakingAll] = useState(false);
  const [claimingAll, setClaimingAll] = useState(false);
  const [unstakingAll, setUnstakingAll] = useState(false);
  const [updatingProject, setUpdatingProject] = useState(false);
  const [syncingNativeRewards, setSyncingNativeRewards] = useState(false);
  const [withdrawingAllRewards, setWithdrawingAllRewards] = useState(false);
  const [updatingHashlist, setUpdatingHashlist] = useState(false);

  const wallet = useAnchorWallet();
  const signerWallet = useWallet();
  const { program } = useProgram({ connection, wallet });

  const publicKey = wallet?.publicKey?.toString();

  const stakingServiceInstance = useMemo(() => {
    return new WLStakingService({
      wallet,
      program,
      connection,
      metadata,
      stakingKey,
    });
  }, [wallet, program, metadata, stakingKey]);

  const [walletState, _fetchWallet] = useAsyncFn(async () => {
    return fetchWalletNFTs({
      publicKey,
      program,
      connection,
      metadata,
      stakingKey,
    });
  }, [publicKey, stakingKey]);

  const [stakedState, _fetchStaked] = useAsyncFn(async () => {
    return fetchProjectStakedNFTs({
      publicKey,
      connection,
      program,
      metadata,
      stakingKey,
    });
  }, [publicKey, program]);

  const walletNFTs = walletState.value || [];
  const isLoadingWallet = walletState.loading;
  const errorWallet = walletState.error;

  const stackedNFTs = stakedState.value || [];
  const isLoadingStaked = stakedState.loading;
  const errorStaked = stakedState.error;

  // Function to re-fetch listing once action is done.
  const reFetch = useCallback(async () => {
    await Promise.all([_fetchStaked(), _fetchWallet()]);
  }, [_fetchStaked, _fetchWallet]);

  // Fetching wallet and staked NFTs
  useAsync(async () => {
    await _fetchWallet();
  }, [_fetchWallet]);

  useAsync(async () => {
    await _fetchStaked();
  }, [_fetchStaked]);

  if (errorWallet || errorStaked) {
    console.error(errorStaked, errorWallet);
  }

  const sleep = (ms: any) => new Promise((r) => setTimeout(r, ms));

  const manageError = function (err: Error) {
    Sentry.captureException(err, {
      tags: stakingServiceInstance?.getDebugInfo(),
    });

    const message = parseSendTransactionError(err);

    toast.error("Error: " + message, {
      duration: 5000,
      position: "bottom-center",
    });
  };

  const stakeAll = useCallback(async () => {
    if (!stakingServiceInstance) {
      return;
    }
    const timeoutToast = new TimeoutToast();
    const walletNFTsMints = walletNFTs.map((walletNFT) =>
      walletNFT.mint.toString()
    );
    try {
      setStakingAll(true);

      const signaturesPromises = await stakingServiceInstance.stakeAllSend(
        walletNFTsMints
      );

      const signatures = await Promise.all(signaturesPromises as any);

      timeoutToast.prepare(signatures);

      await Promise.all(
        stakingServiceInstance.confirmTransactions(signatures) as any
      );

      sleep(1000);
      await reFetch();

      toast.success("All NFTs staked!", {
        duration: 5000,
        position: "bottom-center",
      });
    } catch (err: any) {
      Sentry.withScope((scope) => {
        scope.setExtra("mints", walletNFTsMints);
        Sentry.captureException(err);
      });
      manageError(err);
    } finally {
      timeoutToast.dismiss();
      setStakingAll(false);
    }
  }, [stakingServiceInstance, walletNFTs]);

  const unstakeAll = useCallback(async () => {
    if (!stakingServiceInstance) {
      return;
    }
    const timeoutToast = new TimeoutToast();
    try {
      setUnstakingAll(true);
      const stakedNFTsMints = stackedNFTs.map((stakedNFT) =>
        stakedNFT.mint.toString()
      );
      const signaturesPromises = await stakingServiceInstance.unstakeAllSend(
        stakedNFTsMints
      );

      const signatures = await Promise.all(signaturesPromises as any);

      timeoutToast.prepare(signatures);

      await Promise.all(
        stakingServiceInstance.confirmTransactions(signatures) as any
      );

      sleep(1000);
      await reFetch();

      toast.success("All NFTs unstaked!", {
        duration: 5000,
        position: "bottom-center",
        icon: "💸",
      });
    } catch (err: any) {
      manageError(err);
    } finally {
      timeoutToast.dismiss();
      setUnstakingAll(false);
    }
  }, [stakingServiceInstance, stackedNFTs]);

  const claimAll = useCallback(async () => {
    if (!stakingServiceInstance) {
      return;
    }
    const timeoutToast = new TimeoutToast();
    try {
      setClaimingAll(true);
      const stakedNFTsMints = stackedNFTs.map((stakedNFT) =>
        stakedNFT.mint.toString()
      );
      const signaturesPromises = await stakingServiceInstance.claimAllSend(
        stakedNFTsMints
      );

      const signatures = await Promise.all(signaturesPromises as any);

      timeoutToast.prepare(signatures);

      await Promise.all(
        stakingServiceInstance.confirmTransactions(signatures) as any
      );

      toast.success("All rewards claimed!", {
        duration: 5000,
        position: "bottom-center",
        icon: "💸",
      });
    } catch (err: any) {
      manageError(err);
    } finally {
      timeoutToast.dismiss();
      setClaimingAll(false);
    }
  }, [stakingServiceInstance, stackedNFTs]);

  const updateProject = useCallback(
    async (newDailyRewards: number, newOwner: string, newMints: any) => {
      if (!stakingServiceInstance || !stakingServiceInstance.wallet) {
        return;
      }

      const rewards = await stakingServiceInstance.getRewardsAddressBalance();
      if (!rewards) {
        return;
      }

      const { dailyRewards, decimals } = rewards;

      // newDailyRewards can be -1. If that's the case, we use the current daily rewards
      if (newDailyRewards < 0) {
        newDailyRewards = dailyRewards;
      }

      // newOwner can be empty. If that's the case, we use the current owner
      let newOwnerPublicKey = null;
      try {
        newOwnerPublicKey = new PublicKey(newOwner);
      } catch (err) {
        newOwnerPublicKey = stakingServiceInstance.wallet.publicKey;
      }

      // newMints can be empty. If that's the case, we use the current tree
      let newTree = null;
      try {
        /*
        Expected format for newMints
        [
          {
              "mint": "5hBp6r269Uoj7ajRRa49NpwpTgLrfDcqYriEDEMqBfsu",
              "rarityMultiplier": 50
          },
          ...
        ]
        */
        if (newMints.length > 0) {
          const newMetadata = newMints.map((meta: any) => ({
            mint: meta.mint,
            rarityMultiplier: meta.rarityMultiplier,
          }));
          newTree = WLStakingService.getProjectTree(newMetadata);
        } else {
          console.log("Fallback to current tree");
          newTree = stakingServiceInstance.tree;
        }
      } catch (err) {
        console.log("Fallback to current tree");
        newTree = stakingServiceInstance.tree;
      }

      const timeoutToast = new TimeoutToast();
      try {
        setUpdatingProject(true);

        const signature = await stakingServiceInstance.updateStakingProject({
          timestamp: Math.round(new Date("2022-01-01").getTime() / 1000), // TODO change to input
          dailyRewards: newDailyRewards * Math.pow(10, decimals),
          newOwner: newOwnerPublicKey,
          tree: newTree,
        });

        if (!signature) {
          return;
        }

        timeoutToast.prepare([signature]);

        await stakingServiceInstance.confirmTransaction(signature);

        toast.success("Project updated! Reload to see changes", {
          duration: 5000,
          position: "bottom-center",
        });
      } catch (err: any) {
        manageError(err);
      } finally {
        timeoutToast.dismiss();
        setUpdatingProject(false);
      }
    },
    [stakingServiceInstance]
  );

  const syncNativeRewards = useCallback(async () => {
    if (!stakingServiceInstance || !stakingServiceInstance.wallet) {
      return;
    }

    const timeoutToast = new TimeoutToast();
    try {
      setSyncingNativeRewards(true);

      const signature = await stakingServiceInstance.syncNativeRewards();

      if (!signature) {
        return;
      }

      timeoutToast.prepare([signature]);

      await stakingServiceInstance.confirmTransaction(signature);

      toast.success("Rewards synced! Reload to see changes", {
        duration: 5000,
        position: "bottom-center",
      });
    } catch (err: any) {
      manageError(err);
    } finally {
      timeoutToast.dismiss();
      setSyncingNativeRewards(false);
    }
  }, [stakingServiceInstance]);

  const withdrawAllRewards = useCallback(async () => {
    if (!stakingServiceInstance || !stakingServiceInstance.wallet) {
      return;
    }

    const timeoutToast = new TimeoutToast();
    try {
      setWithdrawingAllRewards(true);

      const signature = await stakingServiceInstance.withdrawAllRewards();

      if (!signature) {
        return;
      }

      timeoutToast.prepare([signature]);

      await stakingServiceInstance.confirmTransaction(signature);

      toast.success("Rewards withdrawn! Reload to see changes", {
        duration: 5000,
        position: "bottom-center",
      });
    } catch (err: any) {
      manageError(err);
    } finally {
      timeoutToast.dismiss();
      setWithdrawingAllRewards(false);
    }
  }, [stakingServiceInstance]);

  const updateHashlist = useCallback(
    async (newMints: string) => {
      const wallet = signerWallet;
      if (
        !newMints ||
        !wallet.publicKey ||
        !wallet.signMessage ||
        !stakingServiceInstance ||
        !stakingServiceInstance.wallet
      ) {
        return;
      }

      const timeoutToast = new TimeoutToast();
      try {
        setUpdatingHashlist(true);

        var newMintsArray = JSON.parse(newMints);
        // Try to create a tree to make sure the input is valid
        try {
          if (newMintsArray.length > 0) {
            // Validate input
            /*
            newMintsArray have two valid formats:
            
            ["5hBp6r269Uoj7ajRRa49NpwpTgLrfDcqYriEDEMqBfsu", ...]
            
            or

            [
              {
                  "mint": "5hBp6r269Uoj7ajRRa49NpwpTgLrfDcqYriEDEMqBfsu",
                  "rarityMultiplier": 50
              },
              ...
            ]

            We'll transform it to the latter
            */
            // In case the newMintsArray is just an array of mint addresses, map it to the proper format
            if (
              typeof newMintsArray[0] === "string" ||
              newMintsArray[0] instanceof String
            ) {
              const formattedNewMintsArray = newMintsArray.map((mint: any) => ({
                mint: mint,
                rarityMultiplier: 100,
              }));
              newMintsArray = formattedNewMintsArray;
            }

            const newMetadata = newMintsArray.map((meta: any) => ({
              mint: meta.mint,
              rarityMultiplier: meta.rarityMultiplier,
            }));
            WLStakingService.getProjectTree(newMetadata);
          } else {
            throw new Error("Input can't be an empty hashlist");
          }
        } catch (err) {
          throw new Error("Input needs to be a valid list of mints");
        }

        // Sign to verify ownership of the project
        const message = new TextEncoder().encode(
          "FloppyLabs Owner verification signature"
        );
        // Sign the bytes using the wallet
        const signature = await wallet.signMessage(message);
        // Verify that the bytes were signed using the private key that matches the known public key
        if (
          !sign.detached.verify(
            message,
            signature,
            stakingServiceInstance.wallet.publicKey.toBytes()
          )
        )
          throw new Error("Invalid signature!");

        const apiService = new ApiService();

        // Update mints in database
        const updateResult = await apiService.updateHashlist(
          stakingServiceInstance.stakingKey.toString(),
          bs58.encode(signature),
          newMintsArray
        );

        if (updateResult.status != 200)
          throw new Error("Error updating hashlist in db!");

        // Revalidate static props of project page to make sure new data is fetched on reload
        const revalidateResult = await apiService.revalidate(slug);
        console.log(revalidateResult);
        if (revalidateResult.status != 200)
          throw new Error("Error revalidating page!");

        // Update mints on chain
        await updateProject(-1, "", newMintsArray);
      } catch (err: any) {
        manageError(err);
      } finally {
        timeoutToast.dismiss();
        setUpdatingHashlist(false);
      }
    },
    [stakingServiceInstance]
  );

  return (
    <WLContext.Provider
      value={{
        metadata,
        stakingKey,
        walletNFTs,
        isLoadingWallet,
        errorWallet,
        stackedNFTs,
        isLoadingStaked,
        errorStaked,
        publicKey: wallet?.publicKey,
        reFetch,
        stakingServiceInstance,
        stakeAll,
        stakingAll,
        claimAll,
        claimingAll,
        unstakeAll,
        unstakingAll,
        updateProject,
        updatingProject,
        syncNativeRewards,
        syncingNativeRewards,
        withdrawAllRewards,
        withdrawingAllRewards,
        updateHashlist,
        updatingHashlist,
        slug,
      }}
    >
      {children}
    </WLContext.Provider>
  );
};

export default WLProjectProvider;
