declare type CardNFTData = {
  uri: string;
  name: string;
};

export declare type CardNFT = {
  data: CardNFTData;
  mint: string;
  staked?: boolean;
  rarityMultiplier: number;
};

export declare type StakedProject = {
  name: string;
  slug: string;
};

export declare type StakedNFT = {
  data: CardNFTData;
  mint: string;
  rarityMultiplier: number;
  stakingKey: string;
  lastClaim: string;
  stakedAt: string;
  project: StakedProject;
};

export declare type MetadataFloppy = {
  mint: string;
  rarityMultiplier: number;
};

export declare type StakedNFTInfo = {
  pendingRewards: number;
  lastClaim: number;
  stakedAt: number;
};

export declare type FormData = {
  projectName: string;
  projectSlug: string;
  projectDescription: string;
  imageUrl: string;
  tokenMintAddress: string;
  dailyRewards: string;
  goLiveDate: string;
  hashJson: string;
};

export declare type CreationData = {
  projectName: string;
  projectSlug: string;
  projectDescription: string;
  imageUrl: string;
  tokenMintAddress: string;
  dailyRewards: number;
  goLiveDate: string;
};

export declare type FormProjectData = {
  projectName: string;
  projectSlug: string;
  projectDescription: string;
  imageUrl: string;
  tokenMintAddress: string;
  dailyRewards: string;
  goLiveDate: string;
  hashList: string;
};

export declare type FormRarityData = {
  mintList: string[];
};

export declare type ConfirmationData = {
  projectName: string;
  projectSlug: string;
  projectDescription: string;
  mintAddress: string;
  dailyRewards: number;
  goLiveDate: number;
  rewardsAccount: string;
  stakingKey: string;
};

export declare type ParsedNFTData = {
  name: string;
  uri: string;
};

export declare type ParsedNFT = {
  mint: string;
  data: ParsedNFTData;
};

export declare type ProjectMetadata = MetadataFloppy[];

export enum AppLayout {
  SUBMIT_PAGE = "SUBMIT_PAGE",
  PROJECT_PAGE = "PROJECT_PAGE",
  PROFILE_PAGE = "PROFILE_PAGE",
}

export {};
