import * as anchor from "@project-serum/anchor";
import { AnchorWallet } from "@solana/wallet-adapter-react";
import {
  Connection,
  Keypair,
  PublicKey,
  SystemProgram,
  SYSVAR_CLOCK_PUBKEY,
  SYSVAR_RENT_PUBKEY,
  Transaction,
  TransactionInstruction,
  TransactionSignature,
  SYSVAR_INSTRUCTIONS_PUBKEY,
  ComputeBudgetProgram,
} from "@solana/web3.js";

import {
  Mint,
  getAccount,
  getMint,
  getAssociatedTokenAddress,
  createAssociatedTokenAccountInstruction,
  createCloseAccountInstruction,
  createSyncNativeInstruction,
  TOKEN_PROGRAM_ID,
  NATIVE_MINT,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  createAssociatedTokenAccountIdempotentInstruction,
} from "@solana/spl-token";

import { ProjectMetadata, StakedNFTInfo } from "types";
import { buildLeaves, MerkleTree } from "utils/merkleTree";
import config from "config";
import { BN } from "@project-serum/anchor";

import {
  MintState,
  findMintStatePk,
  findPolicyPk,
  CMT_PROGRAM,
  PROGRAM_ADDRESS,
  PROGRAM_ID,
  LARGER_COMPUTE_UNIT,
} from "@magiceden-oss/open_creator_protocol";
import { Metaplex, findMetadataPda } from "@metaplex-foundation/js";
import {
  Metadata,
  PROGRAM_ID as TOKEN_METADATA_PROGRAM,
} from "@metaplex-foundation/mpl-token-metadata";
import { PROGRAM_ID as TOKEN_AUTH_RULES_ID } from "@metaplex-foundation/mpl-token-auth-rules";
import { getMetaplexCluster } from "utils/metaplexUtils";

const FEES_LAMPORTS = 10_000_000;

const FEES_ACCOUNT: PublicKey = new PublicKey(config.FEES_ACCOUNT);

type Params = {
  program?: anchor.Program;
  wallet?: AnchorWallet;
  connection: Connection;
  metadata: ProjectMetadata;
  stakingKey: string;
};

const stakingAccounts: {
  [key: string]: any;
} = {};

const balanceAccounts: {
  [key: string]: any;
} = {};

class WLStakingService {
  program?: anchor.Program;
  wallet?: AnchorWallet;
  connection: Connection;
  metadata: ProjectMetadata;
  tree: MerkleTree;
  stakingKey: PublicKey;
  metaplex: Metaplex;

  constructor({ program, wallet, connection, metadata, stakingKey }: Params) {
    this.program = program;
    this.wallet = wallet;
    this.connection = connection;
    this.metadata = metadata;

    this.tree = WLStakingService.getProjectTree(metadata);
    this.stakingKey = new PublicKey(stakingKey);

    this.metaplex = Metaplex.make(connection, {
      cluster: getMetaplexCluster(),
    });
  }

  static getProjectTree(metadata: ProjectMetadata) {
    const leaves = buildLeaves(
      metadata.map((e) => ({
        mint: new PublicKey(e.mint),
        rarityMultiplier: e.rarityMultiplier,
      }))
    );
    return new MerkleTree(leaves);
  }

  static generateStakingKey(): PublicKey {
    return Keypair.generate().publicKey;
  }

  private async getStakingAddress(): Promise<PublicKey | undefined> {
    if (!this.program) {
      return;
    }

    const [stakingAddress] = await PublicKey.findProgramAddress(
      [Buffer.from("staking", "utf8"), this.stakingKey.toBuffer()],
      this.program?.programId
    );

    return stakingAddress;
  }

  async getStakingAccount() {
    if (!this.program) {
      return;
    }
    const stakingAddress = await this.getStakingAddress();
    if (!stakingAddress) {
      return;
    }

    const stringAddress = stakingAddress.toString();

    if (stakingAccounts[stringAddress]) {
      return stakingAccounts[stringAddress];
    }

    stakingAccounts[stringAddress] = await this.program.account.staking.fetch(
      stakingAddress
    );

    return stakingAccounts[stringAddress];
  }

  async getOwner(): Promise<{ owner: PublicKey } | undefined> {
    const stakingAccount = await this.getStakingAccount();

    if (!stakingAccount) {
      return;
    }

    const owner = stakingAccount.owner;

    return { owner };
  }

  async getNftStakedTotal(): Promise<
    { staked: number; total: number } | undefined
  > {
    const stakingAccount = await this.getStakingAccount();

    if (!stakingAccount) {
      return;
    }

    const total = this.metadata.length;
    const staked = parseInt(stakingAccount.nftsStaked.toString(), 10);

    return { staked, total };
  }

  async getRewardsAddressBalance(): Promise<
    | {
        balance: number;
        account: string;
        mint: string;
        dailyRewards: number;
        decimals: number;
      }
    | undefined
  > {
    const stakingAccount = await this.getStakingAccount();

    if (!stakingAccount) {
      return;
    }

    const accountBalance = await this.getAccountBalance(
      stakingAccount.rewardsAccount
    );

    const decimals = accountBalance.value.decimals;

    const dailyRewards = stakingAccount.dailyRewards / Math.pow(10, decimals);

    return {
      balance: accountBalance.value.uiAmount || 0,
      account: stakingAccount.rewardsAccount.toString(),
      mint: stakingAccount.mint.toString(),
      dailyRewards: dailyRewards,
      decimals: decimals,
    };
  }

  private async fetchGeneralStakingInfo() {
    if (!this.wallet || !this.program) {
      return;
    }

    const [stakingAddress] = await PublicKey.findProgramAddress(
      [Buffer.from("staking", "utf8"), this.stakingKey.toBuffer()],
      this.program.programId
    );

    const [escrow] = await PublicKey.findProgramAddress(
      [Buffer.from("escrow", "utf8"), this.stakingKey.toBuffer()],
      this.program.programId
    );

    return {
      escrow,
      stakingAddress,
    };
  }

  private async fetchMintStakingInfo(mint: string) {
    if (!this.wallet || !this.program) {
      return;
    }

    const mintPublicKey = new PublicKey(mint);

    const indexStaked = this.metadata.findIndex((e) => e.mint === mint);

    const [metadataItem] = this.metadata.filter((f) => f.mint === mint);

    const rarityMultiplier = metadataItem.rarityMultiplier;

    const [stakedNft, stakedNftBump] = await PublicKey.findProgramAddress(
      [Buffer.from("staked_nft", "utf8"), mintPublicKey.toBuffer()],
      this.program.programId
    );

    const [deposit, depositBump] = await PublicKey.findProgramAddress(
      [Buffer.from("deposit", "utf8"), mintPublicKey.toBuffer()],
      this.program.programId
    );

    const bumps = {
      stakedNft: stakedNftBump,
      deposit: depositBump,
    };

    return {
      indexStaked,
      bumps,
      deposit,
      stakedNft,
      rarityMultiplier,
    };
  }

  private async getStakeTransactionParams(
    mint: string,
    stakingAddress: PublicKey,
    escrow: PublicKey,
    ocpPolicy: PublicKey | null,
    supportsNonCustodial: boolean
  ) {
    if (!this.wallet || !this.program?.programId) {
      return null;
    }

    const mintPublicKey = new PublicKey(mint);

    const stakingMintInfo = await this.fetchMintStakingInfo(mint);

    if (!stakingMintInfo) {
      return null;
    }

    const { bumps, indexStaked, rarityMultiplier, stakedNft, deposit } =
      stakingMintInfo;

    const parsedTokenAccounts =
      await this.connection.getParsedTokenAccountsByOwner(
        this.wallet.publicKey,
        { mint: mintPublicKey }
      );

    const stakerAccountInfo = parsedTokenAccounts?.value?.find(
      (accountInfo) =>
        accountInfo.account.data["parsed"]["info"]["tokenAmount"]["amount"] > 0
    );

    const stakerAccount = stakerAccountInfo?.pubkey;

    if (!stakerAccount) {
      throw new Error("Impossible to find the token account holding the NFT");
    }

    const proof = this.tree.getProofArray(indexStaked);

    const anchorRarityMultiplier = new anchor.BN(rarityMultiplier);

    let accounts: any = {
      staking: stakingAddress,
      escrow: escrow,
      stakedNft: stakedNft,
      staker: this.wallet?.publicKey,
      mint,
      stakerAccount: stakerAccount,
      feeReceiverAccount: FEES_ACCOUNT,
      clock: SYSVAR_CLOCK_PUBKEY,
      systemProgram: SystemProgram.programId,
    };

    if (!ocpPolicy) {
      accounts.tokenProgram = TOKEN_PROGRAM_ID;
      if (supportsNonCustodial) {
        const metadata = await Metadata.fromAccountAddress(
          this.connection,
          this.metaplex.nfts().pdas().metadata({ mint: mintPublicKey })
        );
        const authorizationRules = metadata.programmableConfig?.ruleSet;

        accounts.masterEdition = this.metaplex
          .nfts()
          .pdas()
          .masterEdition({ mint: mintPublicKey });
        accounts.metadata = this.metaplex
          .nfts()
          .pdas()
          .metadata({ mint: mintPublicKey });
        accounts.tokenRecord = this.metaplex
          .nfts()
          .pdas()
          .tokenRecord({ mint: mintPublicKey, token: stakerAccount });
        accounts.tokenMetadataProgram = TOKEN_METADATA_PROGRAM;
        accounts.authorizationRulesProgram = TOKEN_AUTH_RULES_ID;
        accounts.authorizationRules =
          authorizationRules ?? TOKEN_METADATA_PROGRAM;
        accounts.instructions = SYSVAR_INSTRUCTIONS_PUBKEY;
      } else {
        accounts.rent = SYSVAR_RENT_PUBKEY;
        accounts.depositAccount = deposit;
      }
    } else {
      accounts.ocpPolicy = ocpPolicy;
      accounts.metadata = findMetadataPda(mintPublicKey);
      accounts.ocpMintState = findMintStatePk(mintPublicKey);
      accounts.ocpProgram = PROGRAM_ADDRESS;
      accounts.cmtProgram = CMT_PROGRAM;
      accounts.instructions = SYSVAR_INSTRUCTIONS_PUBKEY;
    }

    return {
      bumps,
      proof,
      anchorRarityMultiplier,
      accounts,
    };
  }

  private async getUnstakeTransactionParams(
    staking: any,
    mint: string,
    ocpPolicy: PublicKey | null,
    rewardToken: Mint,
    userTokenAddress: PublicKey
  ) {
    if (!this.wallet || !this.program?.programId) {
      return null;
    }

    const mintPublicKey = new PublicKey(mint);
    const stakingGeneralInfo = await this.fetchGeneralStakingInfo();
    const stakingMintInfo = await this.fetchMintStakingInfo(mint);

    const instructions: TransactionInstruction[] = [];

    if (!stakingGeneralInfo || !stakingMintInfo) {
      return null;
    }

    const { stakingAddress, escrow } = stakingGeneralInfo;

    const { stakedNft, deposit } = stakingMintInfo;

    const parsedTokenAccounts =
      await this.connection.getParsedTokenAccountsByOwner(
        this.wallet.publicKey,
        { mint: mintPublicKey }
      );

    let stakerAccount;

    // Account to store the NFT in user's wallet
    if (parsedTokenAccounts?.value?.length > 0) {
      stakerAccount = parsedTokenAccounts.value[0].pubkey;
    } else {
      stakerAccount = await getAssociatedTokenAddress(
        mintPublicKey,
        this.wallet.publicKey
      );
      instructions.push(
        createAssociatedTokenAccountInstruction(
          this.wallet.publicKey,
          stakerAccount,
          this.wallet.publicKey,
          mintPublicKey
        )
      );
    }

    const [rewardsAccount] = await PublicKey.findProgramAddress(
      [
        Buffer.from("rewards", "utf8"),
        staking.key.toBuffer(),
        staking.mint.toBuffer(),
      ],
      this.program.programId
    );

    const accounts: any = {
      staking: stakingAddress,
      escrow,
      stakedNft,
      staker: this.wallet.publicKey,
      mint,
      feeReceiverAccount: FEES_ACCOUNT,
      tokenProgram: TOKEN_PROGRAM_ID,
      systemProgram: SystemProgram.programId,
      clock: SYSVAR_CLOCK_PUBKEY,
      rewardsAccount: rewardsAccount,
      rewardsMint: rewardToken.address,
      stakerRewardsAccount: userTokenAddress,
    };

    let isCustodial = false;

    if (!ocpPolicy) {
      accounts.stakerAccount = stakerAccount;
      accounts.tokenProgram = TOKEN_PROGRAM_ID;
      // Check if the token is phisically in the deposit account (custodial)
      let tokenAccount;
      try {
        tokenAccount = await getAccount(this.connection, deposit);
      } catch (e) {}

      isCustodial = tokenAccount ? tokenAccount.amount > 0 : false;
      if (isCustodial) {
        accounts.depositAccount = deposit;
        accounts.ataProgram = ASSOCIATED_TOKEN_PROGRAM_ID;
        accounts.ownerTokenRecord = this.metaplex
          .nfts()
          .pdas()
          .tokenRecord({ mint: mintPublicKey, token: deposit });
      }

      const metadata = await Metadata.fromAccountAddress(
        this.connection,
        this.metaplex.nfts().pdas().metadata({ mint: mintPublicKey })
      );
      const authorizationRules = metadata.programmableConfig?.ruleSet;

      accounts.masterEdition = this.metaplex
        .nfts()
        .pdas()
        .masterEdition({ mint: mintPublicKey });
      accounts.metadata = this.metaplex
        .nfts()
        .pdas()
        .metadata({ mint: mintPublicKey });
      accounts.tokenRecord = this.metaplex
        .nfts()
        .pdas()
        .tokenRecord({ mint: mintPublicKey, token: stakerAccount });
      accounts.tokenMetadataProgram = TOKEN_METADATA_PROGRAM;
      accounts.authorizationRulesProgram = TOKEN_AUTH_RULES_ID;
      accounts.authorizationRules =
        authorizationRules ?? TOKEN_METADATA_PROGRAM;
      accounts.instructions = SYSVAR_INSTRUCTIONS_PUBKEY;
    } else {
      accounts.ocpPolicy = ocpPolicy;
      accounts.metadata = findMetadataPda(new PublicKey(mint));
      accounts.ocpMintState = findMintStatePk(new PublicKey(mint));
      accounts.ocpProgram = PROGRAM_ADDRESS;
      accounts.cmtProgram = CMT_PROGRAM;
      accounts.instructions = SYSVAR_INSTRUCTIONS_PUBKEY;
    }

    const signers: Keypair[] = [];

    return { accounts, signers, instructions, isCustodial };
  }

  private async getClaimTokenInfo(staking: any): Promise<{
    userTokenAddress: PublicKey;
    rewardToken: Mint;
  }> {
    if (!this.program || !this.wallet) {
      // @ts-ignore
      return;
    }

    const userTokenAddress = await getAssociatedTokenAddress(
      staking.mint,
      this.wallet.publicKey
    );

    const rewardToken = await getMint(this.connection, staking.mint);

    return { userTokenAddress, rewardToken };
  }

  private async getInstructionsForClaim(
    staking: any,
    rewardToken: Mint,
    userTokenAddress: PublicKey
  ): Promise<{
    preInstructions: TransactionInstruction[];
    postInstructions: TransactionInstruction[];
  }> {
    if (!this.program || !this.wallet) {
      //@ts-ignore
      return;
    }

    const preInstructions = [];
    const postInstructions = [];

    // Account to store the rewards
    try {
      await getAccount(this.connection, userTokenAddress);
    } catch (err) {
      preInstructions.push(
        createAssociatedTokenAccountIdempotentInstruction(
          this.wallet.publicKey,
          userTokenAddress,
          this.wallet.publicKey,
          staking.mint
        )
      );
    }

    if (rewardToken.address.toString() === NATIVE_MINT.toString()) {
      postInstructions.push(
        createCloseAccountInstruction(
          userTokenAddress,
          this.wallet.publicKey,
          this.wallet.publicKey
        )
      );
    }

    return { preInstructions, postInstructions };
  }

  private async getClaimTransactionAccounts(
    _: string,
    stakingAddress: PublicKey,
    escrow: PublicKey,
    stakedNft: PublicKey,
    staking: any,
    userTokenAddress: PublicKey,
    rewardToken: Mint
  ) {
    if (!this.program || !this.wallet) {
      return;
    }

    const [rewardsAccount] = await PublicKey.findProgramAddress(
      [
        Buffer.from("rewards", "utf8"),
        staking.key.toBuffer(),
        staking.mint.toBuffer(),
      ],
      this.program.programId
    );

    return {
      staking: stakingAddress,
      escrow: escrow,
      stakedNft: stakedNft,
      staker: this.wallet.publicKey,
      mint: rewardToken.address,
      stakerAccount: userTokenAddress,
      rewardsAccount: rewardsAccount,
      tokenProgram: TOKEN_PROGRAM_ID,
      clock: SYSVAR_CLOCK_PUBKEY,
      rent: SYSVAR_RENT_PUBKEY,
      systemProgram: SystemProgram.programId,
    };
  }

  confirmTransaction(signature: TransactionSignature | undefined) {
    if (!signature) {
      return;
    }
    return this.connection.confirmTransaction(signature);
  }

  confirmTransactions(signatures: TransactionSignature[] | undefined) {
    if (!signatures) {
      return;
    }
    const confirmPromises = [];
    for (let signature of signatures) {
      confirmPromises.push(this.connection.confirmTransaction(signature));
    }
    return confirmPromises;
  }

  private async sendTransaction(
    tx: Transaction,
    signers: Keypair[] = []
  ): Promise<string | undefined> {
    if (!this.wallet) {
      return;
    }

    const blockHash = await this.connection.getLatestBlockhash("finalized");
    tx.recentBlockhash = blockHash.blockhash;
    tx.feePayer = this.wallet.publicKey;

    for (let signer of signers) {
      tx.partialSign(signer);
    }

    const signedTransaction = await this.wallet.signTransaction(tx);

    const transaction = signedTransaction.serialize();

    return this.connection.sendRawTransaction(transaction);
  }

  private async sendTransactions(
    txs: Transaction[],
    signers: Keypair[][] = []
  ): Promise<Promise<string | undefined>[] | undefined> {
    if (!this.wallet) {
      return;
    }

    await Promise.all(
      txs.map(async (tx) => {
        if (!this.wallet) {
          return;
        }
        const blockHash = await this.connection.getLatestBlockhash("finalized");
        tx.recentBlockhash = blockHash.blockhash;
        tx.feePayer = this.wallet.publicKey;
        const txSigners = signers[txs.indexOf(tx)];
        if (txSigners) {
          for (let signer of txSigners) {
            tx.partialSign(signer);
          }
        }
      })
    );

    const signedTransactions = await this.wallet.signAllTransactions(txs);

    const transactionPromises = [];
    for (let signedTransaction of signedTransactions) {
      const transaction = signedTransaction.serialize();
      transactionPromises.push(this.connection.sendRawTransaction(transaction));
    }

    return transactionPromises;
  }

  async stakeSend(mint: string) {
    if (!this.wallet || !this.program) {
      return;
    }

    const mintPublicKey = new PublicKey(mint);

    // Check if the mint is OCP
    let ocpPolicy;
    try {
      const mintState = await MintState.fromAccountAddress(
        this.connection,
        findMintStatePk(mintPublicKey)
      );
      ocpPolicy = mintState.policy;
    } catch (err) {
      ocpPolicy = null;
    }

    // Check if the project can be staked in a non-custodial way
    let supportsNonCustodial = false;
    if (!ocpPolicy) {
      const mintInfo = await getMint(this.connection, mintPublicKey);
      // Mints with freezing disabled don't support non-custodial staking
      if (mintInfo.freezeAuthority) {
        supportsNonCustodial = true;
      }
    }

    const stakingGeneralInfo = await this.fetchGeneralStakingInfo();

    if (!stakingGeneralInfo) {
      return;
    }

    const { stakingAddress, escrow } = stakingGeneralInfo;

    const transactionParams = await this.getStakeTransactionParams(
      mint,
      stakingAddress,
      escrow,
      ocpPolicy,
      supportsNonCustodial
    );

    if (!transactionParams) {
      return;
    }

    const { bumps, proof, anchorRarityMultiplier, accounts } =
      transactionParams;

    const signers: Keypair[] = [];
    const instructions: TransactionInstruction[] = [];

    instructions.push(
      ComputeBudgetProgram.setComputeUnitLimit({ units: LARGER_COMPUTE_UNIT })
    );
    let transaction;
    if (!ocpPolicy) {
      if (supportsNonCustodial) {
        transaction = await this.program.methods
          .stakeMpl(bumps, proof, anchorRarityMultiplier)
          .accounts(accounts)
          .preInstructions(instructions)
          .transaction();
      } else {
        transaction = await this.program.methods
          .stakeNft(bumps, proof, anchorRarityMultiplier)
          .accounts(accounts)
          .preInstructions(instructions)
          .transaction();
      }
    } else {
      transaction = await this.program.methods
        .stakeOcp(bumps, proof, anchorRarityMultiplier)
        .accounts(accounts)
        .preInstructions(instructions)
        .transaction();
    }

    if (!ocpPolicy && !supportsNonCustodial) {
      // Close account holding the NFT, to return 0.002 SOL to staker
      transaction.instructions.push(
        createCloseAccountInstruction(
          accounts.stakerAccount,
          this.wallet.publicKey,
          this.wallet.publicKey
        )
      );
    }

    return this.sendTransaction(transaction, signers);
  }

  async stakeAllSend(mints: string[]) {
    if (!this.wallet || !this.program) {
      return;
    }

    const firstMint = new PublicKey(mints[0]);
    // Check if the mint is OCP (just checking the first mint, as the rest will be the same, saving RPC calls)
    let ocpPolicy: any;
    try {
      const mintState = await MintState.fromAccountAddress(
        this.connection,
        findMintStatePk(firstMint)
      );
      ocpPolicy = mintState.policy;
    } catch (err) {
      ocpPolicy = null;
    }

    // Check if the project can be staked in a non-custodial way
    let supportsNonCustodial = false;
    if (!ocpPolicy) {
      const mintInfo = await getMint(this.connection, firstMint);
      // Mints with freezing disabled don't support non-custodial staking
      if (mintInfo.freezeAuthority) {
        supportsNonCustodial = true;
      }
    }

    const stakingGeneralInfo = await this.fetchGeneralStakingInfo();

    if (!stakingGeneralInfo) {
      return;
    }

    const { stakingAddress, escrow } = stakingGeneralInfo;

    const signers: Keypair[][] = [];

    const transactions = await Promise.all(
      mints.map(async (mint) => {
        if (!this.wallet || !this.program) {
          return;
        }
        const stakeAllTx = new Transaction();

        const transactionParams = await this.getStakeTransactionParams(
          mint,
          stakingAddress,
          escrow,
          ocpPolicy,
          supportsNonCustodial
        );

        if (!transactionParams) {
          return;
        }

        const { bumps, proof, anchorRarityMultiplier, accounts } =
          transactionParams;

        let instruction;
        if (!ocpPolicy) {
          if (supportsNonCustodial) {
            instruction = await this.program.methods
              .stakeMpl(bumps, proof, anchorRarityMultiplier)
              .accounts(accounts)
              .instruction();
          } else {
            instruction = await this.program.methods
              .stakeNft(bumps, proof, anchorRarityMultiplier)
              .accounts(accounts)
              .instruction();
          }
        } else {
          instruction = await this.program.methods
            .stakeOcp(bumps, proof, anchorRarityMultiplier)
            .accounts(accounts)
            .instruction();
        }

        stakeAllTx.add(
          ComputeBudgetProgram.setComputeUnitLimit({
            units: LARGER_COMPUTE_UNIT,
          })
        );

        stakeAllTx.add(instruction);

        if (!ocpPolicy && !supportsNonCustodial) {
          // Close account holding the NFT, to return 0.002 SOL to staker
          stakeAllTx.instructions.push(
            createCloseAccountInstruction(
              accounts.stakerAccount,
              this.wallet.publicKey,
              this.wallet.publicKey
            )
          );
        }

        return stakeAllTx;
      })
    );

    const curatedTransactions: Transaction[] = transactions.filter(
      (transaction) => transaction
    ) as Transaction[];

    return this.sendTransactions(curatedTransactions, signers);
  }

  async unstakeSend(mint: string, pendingRewards: number) {
    if (!this.wallet || !this.program) {
      return;
    }

    const stakingGeneralInfo = await this.fetchGeneralStakingInfo();

    if (!stakingGeneralInfo) {
      return;
    }

    const { stakingAddress } = stakingGeneralInfo;

    const staking = await this.program.account.staking.fetch(stakingAddress);

    // Check if the mint is OCP (just checking the first mint, as the rest will be the same, saving RPC calls)
    let ocpPolicy;
    try {
      const mintState = await MintState.fromAccountAddress(
        this.connection,
        findMintStatePk(new PublicKey(mint))
      );
      ocpPolicy = mintState.policy;
    } catch (err) {
      ocpPolicy = null;
    }

    const { userTokenAddress, rewardToken } = await this.getClaimTokenInfo(
      staking
    );

    const transactionParams = await this.getUnstakeTransactionParams(
      staking,
      mint,
      ocpPolicy,
      rewardToken,
      userTokenAddress
    );

    if (!transactionParams) {
      return;
    }

    const { preInstructions, postInstructions } =
      await this.getInstructionsForClaim(
        staking,
        rewardToken,
        userTokenAddress
      );

    // TODO warn if there are not enough tokens to be claimed
    // Check if rewards wallet has funds, in order to force a claim before unstake
    /*
    const accountBalance = await this.getAccountBalance(
      staking.rewardsAccount as PublicKey
    );
    if (
      pendingRewards &&
      accountBalance &&
      accountBalance.value.uiAmount >= pendingRewards
    ) {
      const claimTx = await this.createClaimTx(mint);

      if (claimTx) {
        transactions.push(claimTx);
        signersArray.push([]);
      }
    }*/

    const { accounts, signers, instructions, isCustodial } = transactionParams;

    instructions.push(
      ComputeBudgetProgram.setComputeUnitLimit({ units: LARGER_COMPUTE_UNIT })
    );

    const preInstructionsComplete = instructions.concat(preInstructions);

    let transaction;
    if (!ocpPolicy) {
      if (isCustodial) {
        transaction = await this.program.methods
          .unstakeMplCustodial()
          .accounts(accounts)
          .preInstructions(preInstructionsComplete)
          .postInstructions(postInstructions)
          .transaction();
      } else {
        transaction = await this.program.methods
          .unstakeMpl()
          .accounts(accounts)
          .preInstructions(preInstructionsComplete)
          .postInstructions(postInstructions)
          .transaction();
      }
    } else {
      transaction = await this.program.methods
        .unstakeOcp()
        .accounts(accounts)
        .preInstructions(preInstructionsComplete)
        .postInstructions(postInstructions)
        .transaction();
    }

    return this.sendTransaction(transaction);
  }

  async unstakeAllSend(mints: string[]) {
    if (!this.wallet || !this.program) {
      return;
    }

    const stakingGeneralInfo = await this.fetchGeneralStakingInfo();

    if (!stakingGeneralInfo) {
      return;
    }

    const { stakingAddress } = stakingGeneralInfo;

    const staking = await this.program.account.staking.fetch(stakingAddress);

    // Check if the mint is OCP (just checking the first mint, as the rest will be the same, saving RPC calls)
    let ocpPolicy: any;
    try {
      const mintState = await MintState.fromAccountAddress(
        this.connection,
        findMintStatePk(new PublicKey(mints[0]))
      );
      ocpPolicy = mintState.policy;
    } catch (err) {
      ocpPolicy = null;
    }

    const { userTokenAddress, rewardToken } = await this.getClaimTokenInfo(
      staking
    );

    const { preInstructions, postInstructions } =
      await this.getInstructionsForClaim(
        staking,
        rewardToken,
        userTokenAddress
      );

    const transactions = await Promise.all(
      mints.map(async (mint) => {
        if (!this.wallet || !this.program) {
          return;
        }

        const transactionParams = await this.getUnstakeTransactionParams(
          staking,
          mint,
          ocpPolicy,
          rewardToken,
          userTokenAddress
        );

        if (!transactionParams) {
          return;
        }

        // TODO warn if there are not enough tokens to be claimed
        // Check if rewards wallet has funds, in order to force a claim before unstake
        /*
      const accountBalance = await this.getAccountBalance(
        staking.rewardsAccount as PublicKey
      );
      if (
        pendingRewards &&
        accountBalance &&
        accountBalance.value.uiAmount >= pendingRewards
      ) {
        const claimTx = await this.createClaimTx(mint);
  
        if (claimTx) {
          transactions.push(claimTx);
          signersArray.push([]);
        }
      }*/

        const { accounts, signers, instructions, isCustodial } =
          transactionParams;

        instructions.push(
          ComputeBudgetProgram.setComputeUnitLimit({
            units: LARGER_COMPUTE_UNIT,
          })
        );

        const preInstructionsComplete = instructions.concat(preInstructions);

        let transaction;
        if (!ocpPolicy) {
          if (isCustodial) {
            transaction = await this.program.methods
              .unstakeMplCustodial()
              .accounts(accounts)
              .preInstructions(preInstructionsComplete)
              .postInstructions(postInstructions)
              .transaction();
          } else {
            transaction = await this.program.methods
              .unstakeMpl()
              .accounts(accounts)
              .preInstructions(preInstructionsComplete)
              .postInstructions(postInstructions)
              .transaction();
          }
        } else {
          transaction = await this.program.methods
            .unstakeOcp()
            .accounts(accounts)
            .preInstructions(preInstructionsComplete)
            .postInstructions(postInstructions)
            .transaction();
        }
        return transaction;
      })
    );

    const curatedTransactions: Transaction[] = transactions.filter(
      (transaction) => transaction
    ) as Transaction[];

    return this.sendTransactions(curatedTransactions);
  }

  async createClaimTx(mint: string) {
    if (!this.wallet || !this.program) {
      return;
    }

    const stakingGeneralInfo = await this.fetchGeneralStakingInfo();
    const stakingMintInfo = await this.fetchMintStakingInfo(mint);

    if (!stakingGeneralInfo || !stakingMintInfo) {
      return;
    }

    const { stakingAddress, escrow } = stakingGeneralInfo;

    const { stakedNft } = stakingMintInfo;

    const staking = await this.program.account.staking.fetch(stakingAddress);

    const { userTokenAddress, rewardToken } = await this.getClaimTokenInfo(
      staking
    );

    const accounts = await this.getClaimTransactionAccounts(
      mint,
      stakingAddress,
      escrow,
      stakedNft,
      staking,
      userTokenAddress,
      rewardToken
    );

    if (!accounts) {
      return;
    }

    const { preInstructions, postInstructions } =
      await this.getInstructionsForClaim(
        staking,
        rewardToken,
        userTokenAddress
      );

    const claimTx = await this.program.methods
      .claimStaking()
      .accounts(accounts)
      .preInstructions(preInstructions)
      .transaction();

    if (postInstructions?.length) {
      claimTx.instructions.push(...postInstructions);
    }

    return claimTx;
  }

  async claimSend(mint: string) {
    if (!this.wallet || !this.program) {
      return;
    }

    const claimTx = await this.createClaimTx(mint);

    if (!claimTx) {
      return;
    }

    return this.sendTransaction(claimTx);
  }

  async claimAllSend(mints: string[]) {
    if (!this.wallet || !this.program) {
      return;
    }

    const stakingGeneralInfo = await this.fetchGeneralStakingInfo();

    if (!stakingGeneralInfo) {
      return;
    }

    const { stakingAddress, escrow } = stakingGeneralInfo;

    const staking = await this.program.account.staking.fetch(stakingAddress);
    const { userTokenAddress, rewardToken } = await this.getClaimTokenInfo(
      staking
    );

    const { preInstructions, postInstructions } =
      await this.getInstructionsForClaim(
        staking,
        rewardToken,
        userTokenAddress
      );

    const isWrappedSolReward =
      rewardToken.address.toString() === NATIVE_MINT.toString();

    // Split mints in transactions grouping N claim instructions each
    const chunkSize = 10;
    const mintsChunks = [];
    for (let i = 0; i < mints.length; i += chunkSize) {
      const mintsChunk = mints.slice(i, i + chunkSize);
      mintsChunks.push(mintsChunk);
    }

    const transactions = await Promise.all(
      mintsChunks.map(async (mintsChunk) => {
        if (!this.wallet || !this.program) {
          return;
        }

        const claimAllTx = new Transaction();

        // Add pre-instructions (account creation) in an idempotent way
        if (preInstructions?.length) {
          claimAllTx.add(...preInstructions);
        }

        for (let mint of mintsChunk) {
          const stakingMintInfo = await this.fetchMintStakingInfo(mint);

          if (!stakingMintInfo) {
            return;
          }

          const { stakedNft } = stakingMintInfo;
          const accounts = await this.getClaimTransactionAccounts(
            mint,
            stakingAddress,
            escrow,
            stakedNft,
            staking,
            userTokenAddress,
            rewardToken
          );

          if (!accounts) {
            return;
          }

          const claimInstruction = await this.program.methods
            .claimStaking()
            .accounts(accounts)
            .instruction();

          claimAllTx.add(claimInstruction);
        }

        // Only add post-instructions (close account) for Wrapped SOL
        // at the end of every transaction (because we are creating the account on every tx)
        if (postInstructions?.length && isWrappedSolReward) {
          claimAllTx.add(...postInstructions);
        }

        return claimAllTx;
      })
    );

    const curatedTransactions: Transaction[] = transactions.filter(
      (transaction) => transaction
    ) as Transaction[];

    return this.sendTransactions(curatedTransactions);
  }

  async createStakingProject({
    timestamp,
    dailyRewards,
    tokenMintAddress,
  }: {
    timestamp: number;
    dailyRewards: number;
    tokenMintAddress: string;
  }) {
    if (!this.wallet || !this.program) {
      throw new Error("Impossible to create without wallet nor program");
    }

    //timestamp of when the staking will be live. That way the project owner can set it up but closed. For example 1641859200
    const start = new BN(timestamp);

    // the user should input the amount with all decimals (most tokens have 9 decimals,
    // so for example for 1 token unit a day the user should input 1000000000).
    // We may want to improve this in the future but it is hard cause each token can have its own number of decimals.
    // We could probably read the number of decimals of the token from the blockchain -> phase 2
    const _dailyRewards = new BN(dailyRewards);

    const mintRewards = await getMint(
      this.connection,
      new PublicKey(tokenMintAddress)
    );

    const [stakingAddress, stakingBump] = await PublicKey.findProgramAddress(
      [Buffer.from("staking", "utf8"), this.stakingKey.toBuffer()],
      this.program.programId
    );
    const [escrow, escrowBump] = await PublicKey.findProgramAddress(
      [Buffer.from("escrow", "utf8"), this.stakingKey.toBuffer()],
      this.program.programId
    );
    const [rewards, rewardsBump] = await PublicKey.findProgramAddress(
      [
        Buffer.from("rewards", "utf8"),
        this.stakingKey.toBuffer(),
        mintRewards.address.toBuffer(),
      ],
      this.program.programId
    );

    const bumps = {
      staking: stakingBump,
      escrow: escrowBump,
      rewards: rewardsBump,
    };

    await this.program.methods
      .initializeStaking(bumps, _dailyRewards, start, this.tree.getRootArray())
      .accounts({
        stakingKey: this.stakingKey,
        staking: stakingAddress,
        escrow: escrow,
        mint: mintRewards.address,
        rewardsAccount: rewards,
        // @ts-ignore Bad typing.
        owner: this.wallet.publicKey,
        tokenProgram: TOKEN_PROGRAM_ID,
        rent: SYSVAR_RENT_PUBKEY,
        systemProgram: SystemProgram.programId,
      })
      .rpc();

    return {
      stakingKey: this.stakingKey.toString(),
      rewardsAddress: rewards.toString(),
    };
  }

  async updateStakingProject({
    timestamp,
    dailyRewards,
    newOwner,
    tree,
  }: {
    timestamp: number;
    dailyRewards: number;
    newOwner: PublicKey;
    tree: MerkleTree;
  }) {
    if (!this.wallet || !this.program) {
      throw new Error("Impossible to update without wallet nor program");
    }

    //timestamp of when the staking will be live. That way the project owner can set it up but closed. For example 1641859200
    const start = new BN(timestamp);

    // the user should input the amount with all decimals (most tokens have 9 decimals,
    // so for example for 1 token unit a day the user should input 1000000000).
    // We may want to improve this in the future but it is hard cause each token can have its own number of decimals.
    // We could probably read the number of decimals of the token from the blockchain -> phase 2
    const _dailyRewards = new BN(dailyRewards);

    const [stakingAddress] = await PublicKey.findProgramAddress(
      [Buffer.from("staking", "utf8"), this.stakingKey.toBuffer()],
      this.program.programId
    );

    const updateProjectTx = await this.program.methods
      .setStaking(_dailyRewards, start, tree.getRootArray())
      .accounts({
        staking: stakingAddress,
        owner: this.wallet.publicKey,
        newOwner: newOwner,
      })
      .transaction();

    return this.sendTransaction(updateProjectTx);
  }

  async withdrawAllRewards() {
    if (!this.wallet || !this.program) {
      throw new Error("Impossible to update without wallet nor program");
    }

    const stakingGeneralInfo = await this.fetchGeneralStakingInfo();

    if (!stakingGeneralInfo) {
      return;
    }

    const { stakingAddress, escrow } = stakingGeneralInfo;

    const staking: any = await this.program.account.staking.fetch(
      stakingAddress
    );

    const { userTokenAddress, rewardToken } = await this.getClaimTokenInfo(
      staking
    );

    const mintRewards: PublicKey = staking.mint as PublicKey;

    const rewardsAccount: PublicKey = staking.rewardsAccount as PublicKey;

    const accountBalance = await this.getAccountBalance(staking.rewardsAccount);

    const totalRewards = new BN(accountBalance.value.amount);

    const { preInstructions, postInstructions } =
      await this.getInstructionsForClaim(
        staking,
        rewardToken,
        userTokenAddress
      );

    const withdrawAllRewardsTx = await this.program.methods
      .withdrawRewards(totalRewards)
      .accounts({
        staking: stakingAddress,
        escrow: escrow,
        mint: mintRewards,
        rewardsAccount: rewardsAccount,
        owner: this.wallet.publicKey,
        ownerAccount: userTokenAddress,
        tokenProgram: TOKEN_PROGRAM_ID,
      })
      .preInstructions(preInstructions)
      .transaction();

    if (postInstructions?.length) {
      withdrawAllRewardsTx.instructions.push(...postInstructions);
    }

    return this.sendTransaction(withdrawAllRewardsTx);
  }

  async syncNativeRewards() {
    if (!this.wallet || !this.program) {
      throw new Error("Impossible to run tx without wallet nor program");
    }

    const stakingAccount = await this.getStakingAccount();
    const rewardsAccount = stakingAccount.rewardsAccount;

    const syncNativeTx = new Transaction().add(
      createSyncNativeInstruction(rewardsAccount)
    );

    return this.sendTransaction(syncNativeTx);
  }

  private async getAccountBalance(account: PublicKey) {
    const accountStr = account.toString();
    if (balanceAccounts[accountStr]) {
      return balanceAccounts[accountStr];
    }

    balanceAccounts[accountStr] = await this.connection.getTokenAccountBalance(
      account
    );

    return balanceAccounts[accountStr];
  }

  async getStakedAccountForNFTCard(): Promise<{
    stakingAccount: any;
    accountBalance: any;
  } | void> {
    if (!this.program) {
      return;
    }

    const stakingAccount = await this.getStakingAccount();

    if (!stakingAccount) {
      return;
    }

    const accountBalance = await this.getAccountBalance(
      stakingAccount.rewardsAccount
    );

    return { stakingAccount, accountBalance };
  }

  private toFixedNumber(num: number, digits: number, base: number) {
    var pow = Math.pow(base || 10, digits);
    return Math.round(num * pow) / pow;
  }

  async getStakedInfoForMint(mint: string): Promise<StakedNFTInfo> {
    const stakingAccount = await this.getStakingAccount();

    if (!this.program || !stakingAccount) {
      throw new Error("Impossible to get staked info without program");
    }

    const accountBalance = await this.getAccountBalance(
      stakingAccount.rewardsAccount
    );

    const mintPublicKey = new PublicKey(mint);

    const [stakedNftAddress] = await PublicKey.findProgramAddress(
      [Buffer.from("staked_nft", "utf8"), mintPublicKey.toBuffer()],
      this.program.programId
    );

    const stakedNftAccount: any = await this.program.account.stakedNft.fetch(
      stakedNftAddress
    );

    const dailyRewards = stakingAccount.dailyRewards;
    const rarityMultiplier: any =
      stakedNftAccount?.rarityMultiplier?.toString();
    const lastClaim: any = stakedNftAccount?.lastClaim?.toString();
    const stakedAt: any = stakedNftAccount?.stakedAt?.toString();
    const now = new Date().getTime() / 1000;
    const secondsElapsed = now - lastClaim;
    const dailyRewardsAdjusted = (dailyRewards * rarityMultiplier) / 100;
    const rewardsAmount = (dailyRewardsAdjusted * secondsElapsed) / 86400;
    const decimals = accountBalance.value.decimals;
    const pendingRewards = Math.floor(rewardsAmount) / Math.pow(10, decimals);

    return {
      pendingRewards:
        decimals > 4
          ? this.toFixedNumber(pendingRewards, 4, 10)
          : this.toFixedNumber(pendingRewards, decimals, 10),
      lastClaim: lastClaim,
      stakedAt: stakedAt,
    };
  }

  getDebugInfo() {
    return {
      programId: this.program?.programId?.toString(),
      publicKey: this.wallet?.publicKey?.toString(),
      stakingKey: this.stakingKey?.toString(),
    };
  }
}

export { WLStakingService };
