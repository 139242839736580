import React, { useCallback, useState } from "react";
import cx from "classnames";
import { motion } from "framer-motion";
import { useWLProject } from "modules/WLProject";
import { getAppUrl } from "utils/urls";

type Props = {};

const AdminView: React.FC<Props> = ({}) => {
  const [newDailyRewards, setNewDailyRewards] = useState(-1);
  const [newHashList, setNewHashList] = useState("");
  const [newAuthority, setNewAuthority] = useState("");
  const {
    stakingKey,
    updateProject,
    updatingProject,
    syncNativeRewards,
    syncingNativeRewards,
    withdrawAllRewards,
    withdrawingAllRewards,
    updateHashlist,
    updatingHashlist,
  } = useWLProject();

  const noop = () => {};

  const handleUpdateProject = useCallback(() => {
    updateProject(newDailyRewards, newAuthority, []);
  }, [updateProject, newDailyRewards, newAuthority]);

  const handleSyncNativeRewards = useCallback(() => {
    syncNativeRewards();
  }, [syncNativeRewards]);

  const handleWithdrawAllRewards = useCallback(() => {
    withdrawAllRewards();
  }, [withdrawAllRewards]);

  const handleUpdateHashlist = useCallback(() => {
    updateHashlist(newHashList);
  }, [updateHashlist, newHashList]);

  return (
    <div className="card card w-full rounded-lg bg-neutral p-4 shadow-xl">
      <h1 className="text-lg font-semibold">Admin Tools</h1>
      <div className="grid grid-cols-2 gap-4 py-2 md:grid-cols-3 lg:grid-cols-6">
        <div className="card rounded-box card-compact grid flex-grow bg-base-200">
          <div className="card-body">
            <p>
              Update mint <b className="text-primary">hashlist</b>.
            </p>
            <div className="card-actions">
              <textarea
                className="bg-base-400 input mt-4 w-full max-w-xs "
                placeholder='["7kSEFxkUB2DqMQ4Xa3392yPEBThTLy1Ei9t7B5yL41fD",
                    "64bcmHvviJcQrce7419DZhMr1jkCEMWy4ewbE8wE1SMF"]'
                onChange={(event) => setNewHashList(event.target.value)}
              />
              <button
                className={cx(
                  "btn-neutral btn btn-block mt-4 hover:btn-primary",
                  {
                    loading: updatingHashlist,
                  }
                )}
                onClick={updatingHashlist ? noop : handleUpdateHashlist}
                disabled={updatingHashlist}
              >
                Update
              </button>
            </div>
          </div>
        </div>
        <div className="card rounded-box card-compact grid flex-grow bg-base-200">
          <div className="card-body">
            <p>
              Take a <b className="text-primary">snapshot</b> of the staked NFTs
              and the holders staking.
            </p>
            <div className="card-actions">
              <a
                href={getAppUrl("/api/staked?download=true&key=" + stakingKey)}
                className="btn-neutral btn btn-block mt-4 hover:btn-primary"
              >
                Take snapshot
              </a>
            </div>
          </div>
        </div>
        <div className="card rounded-box card-compact grid flex-grow bg-base-200">
          <div className="card-body">
            <p>
              Update the base <b className="text-primary">daily rewards</b> /
              NFT. It will affect all unclaimed rewards.
            </p>
            <div className="card-actions">
              <div className="bg-base-400 input-group input-bordered">
                <input
                  type="number"
                  placeholder="Daily rewards"
                  className="bg-base-400 input mt-4 w-full max-w-xs "
                  onChange={(event) =>
                    setNewDailyRewards(Number(event.target.value))
                  }
                />
                <button
                  className={cx("btn-neutral btn mt-4 hover:btn-primary", {
                    loading: updatingProject,
                  })}
                  onClick={updatingProject ? noop : handleUpdateProject}
                  disabled={updatingProject}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="card rounded-box card-compact grid flex-grow bg-base-200">
          <div className="card-body">
            <p>
              <b className="text-primary">Withdraw</b> all the tokens in the
              rewards wallet. Will leave the rewards wallet empty.
            </p>
            <div className="card-actions">
              <button
                className={cx(
                  "btn-neutral btn btn-block mt-4 hover:btn-primary",
                  {
                    loading: withdrawingAllRewards,
                  }
                )}
                onClick={
                  withdrawingAllRewards ? noop : handleWithdrawAllRewards
                }
                disabled={withdrawingAllRewards}
              >
                Withdraw all rewards
              </button>
            </div>
          </div>
        </div>
        <div className="card rounded-box card-compact grid flex-grow bg-base-200">
          <div className="card-body">
            <p>
              <b className="text-primary">Sync</b> your rewards wallet account
              so it shows the right amount. Only for $SOL rewards.
            </p>
            <div className="card-actions">
              <button
                className={cx(
                  "btn-neutral btn btn-block mt-4 hover:btn-primary",
                  {
                    loading: syncingNativeRewards,
                  }
                )}
                onClick={syncingNativeRewards ? noop : handleSyncNativeRewards}
                disabled={syncingNativeRewards}
              >
                Sync $SOL rewards wallet
              </button>
            </div>
          </div>
        </div>
        <div className="card rounded-box card-compact grid flex-grow bg-base-200">
          <div className="card-body">
            <p>
              Transfer the staking{" "}
              <b className="text-primary">site authority</b> to a different
              wallet. Cannot be undone!
            </p>
            <div className="card-actions">
              <div className="bg-base-400 input-group input-bordered">
                <input
                  type="text"
                  placeholder="New authority"
                  className="bg-base-400 input mt-4 w-full max-w-xs "
                  onChange={(event) => setNewAuthority(event.target.value)}
                />
                <button
                  className={cx("btn-neutral btn mt-4 hover:btn-primary", {
                    loading: updatingProject,
                  })}
                  onClick={updatingProject ? noop : handleUpdateProject}
                  disabled={updatingProject}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminView;
