import { FC } from "react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import Link from "next/link";
import config from "config";
import { getAppUrl } from "utils/urls";

type Props = {
  showProfileButton?: boolean;
};

const NavBar: FC<Props> = ({ showProfileButton }: Props) => {
  return (
    <div className="navbar-container p-4">
      <div className="shadow-l navbar rounded-lg bg-neutral">
        <div className="brand-button navbar-start invisible md:visible">
          <Link href={config.HOME_URL} passHref>
            <a className="btn btn-ghost" target="_blank">
              <label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 fill-secondary"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path d="M3 10v11h6v-7h6v7h6v-11L12,3z" />
                </svg>
              </label>

              <div className="bold ml-2 text-xl font-bold normal-case text-secondary">
                FloppyLabs
              </div>
            </a>
          </Link>
        </div>
        <div className="navbar-end">
          {!!showProfileButton && (
            <Link href={getAppUrl("/me")}>
              <button className="brand-button btn btn-secondary">
                <label>
                  <svg
                    width="16px"
                    height="16px"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    className="bi bi-stack"
                  >
                    <path d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.598.598 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.598.598 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.598.598 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535L7.733.063z" />
                    <path d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.598.598 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.659z" />
                  </svg>
                </label>
                <div className="ml-2">My Staking</div>
              </button>
            </Link>
          )}
          <div className="wallet-wrapper bg-ghost">
            <WalletMultiButton className="bold" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
