import { useMemo } from "react";
import {
  Commitment,
  ConfirmOptions,
  Connection,
  PublicKey,
} from "@solana/web3.js";
import * as anchor from "@project-serum/anchor";
import { AnchorWallet } from "@solana/wallet-adapter-react";
import config from "config";

import idl from "./staking.json";

const programID = new PublicKey(config.STAKING_PROGRAM);

export interface Wallet {
  signTransaction(
    tx: anchor.web3.Transaction
  ): Promise<anchor.web3.Transaction>;

  signAllTransactions(
    txs: anchor.web3.Transaction[]
  ): Promise<anchor.web3.Transaction[]>;

  publicKey: anchor.web3.PublicKey;
}

type ProgramProps = {
  connection: Connection;
  wallet: AnchorWallet | undefined;
};

const providerOptions: ConfirmOptions = {
  preflightCommitment: "recent",
  commitment: "processed" as Commitment,
};

const useProgram = ({ connection, wallet }: ProgramProps) => {
  const program = useMemo(() => {
    const provider = new anchor.AnchorProvider(
      connection,
      wallet as Wallet,
      providerOptions
    );

    return new anchor.Program(idl as any, programID, provider);
  }, [connection, wallet]);

  return {
    program,
  };
};

export default useProgram;
