import { tokenAuthFetchMiddleware } from "@strata-foundation/web3-token-auth";
import { ApiServiceInstance } from "services/ApiService";
import { ConnectionConfig } from "@solana/web3.js";

const getToken = async (): Promise<string> => {
  const { data } = await ApiServiceInstance.getToken();
  return data?.access_token;
};

const FIVE_MINUTES_IN_MILLISECONDS = 5 * 60 * 1000;

const getConnectionConfig = (): ConnectionConfig => {
  return {
    disableRetryOnRateLimit: false,
    fetchMiddleware: tokenAuthFetchMiddleware({
      getToken,
      tokenExpiry: FIVE_MINUTES_IN_MILLISECONDS,
    }),
    commitment: "confirmed",
    confirmTransactionInitialTimeout: 60 * 1000,
  };
};

export { getConnectionConfig };
