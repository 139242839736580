import type { ProjectMetadata } from "types";
import {
  Connection,
  PublicKey,
  AccountInfo,
  ParsedAccountData,
} from "@solana/web3.js";
import { TOKEN_PROGRAM_ID, getAccount } from "@solana/spl-token";
import {
  isValidSolanaAddress,
  getSolanaMetadataAddress,
  decodeTokenMetadata,
} from "@nfteyez/sol-rayz";
import {
  MintState,
  findMintStatePk,
} from "@magiceden-oss/open_creator_protocol";
import { ParsedNFT } from "types";
import * as anchor from "@project-serum/anchor";
import { fetchStakedAccounts } from "services/fetchStakedNFTs";

const mapWallet = (metadata: ProjectMetadata) => (parsedNfts: ParsedNFT[]) => {
  const collectionMints = metadata.map((e) => e.mint);

  return parsedNfts
    .filter((e) => collectionMints.includes(e.mint))
    .map((e) => {
      const [metadataItem] = metadata.filter((f) => f.mint === e.mint);
      return {
        mint: e.mint,
        data: {
          name: e?.data?.name,
          uri: e?.data?.uri,
        },
        rarityMultiplier: metadataItem.rarityMultiplier,
      };
    });
};

type Params = {
  publicKey?: string;
  program: anchor.Program;
  metadata: ProjectMetadata;
  connection: Connection;
  stakingKey: string;
};

const chunk = (list: PublicKey[], size: number) =>
  [...Array(Math.ceil(list.length / size))].map((_) => list.splice(0, size));

const fetchWalletNFTs = async ({
  publicKey,
  program,
  connection,
  metadata,
  stakingKey,
}: Params) => {
  if (!connection || !publicKey || !stakingKey) {
    return [];
  }

  const collectionMints = metadata.map((e) => e.mint);

  const parsedNfts: ParsedNFT[] = await getCollectionParsedNftAccountsByOwner({
    publicAddress: publicKey,
    program,
    collectionMints,
    connection,
    stakingKey,
  });

  if (!parsedNfts?.length) {
    return [];
  }

  return mapWallet(metadata)(parsedNfts);
};

/* 
   Code adapted from
   https://github.com/NftEyez/sol-rayz/blob/main/packages/sol-rayz/src/getParsedNftAccountsByOwner.ts 
   to be able to filter by collection mints, and avoid fetching ALL NFTs in the wallet
*/

type StringPublicKey = string;

enum MetadataKey {
  Uninitialized = 0,
  MetadataV1 = 4,
  EditionV1 = 1,
  MasterEditionV1 = 2,
  MasterEditionV2 = 6,
  EditionMarker = 7,
}

export class Creator {
  address: StringPublicKey;
  verified: boolean;
  share: number;

  constructor(args: {
    address: StringPublicKey;
    verified: boolean;
    share: number;
  }) {
    this.address = args.address;
    this.verified = args.verified;
    this.share = args.share;
  }
}

class Data {
  name: string;
  symbol: string;
  uri: string;
  sellerFeeBasisPoints: number;
  creators: Creator[] | null;
  constructor(args: {
    name: string;
    symbol: string;
    uri: string;
    sellerFeeBasisPoints: number;
    creators: Creator[] | null;
  }) {
    this.name = args.name;
    this.symbol = args.symbol;
    this.uri = args.uri;
    this.sellerFeeBasisPoints = args.sellerFeeBasisPoints;
    this.creators = args.creators;
  }
}

class Metadata {
  key: MetadataKey;
  updateAuthority: StringPublicKey;
  mint: StringPublicKey;
  data: Data;
  primarySaleHappened: boolean;
  isMutable: boolean;
  editionNonce: number | null;

  // set lazy
  masterEdition?: StringPublicKey;
  edition?: StringPublicKey;

  constructor(args: {
    updateAuthority: StringPublicKey;
    mint: StringPublicKey;
    data: Data;
    primarySaleHappened: boolean;
    isMutable: boolean;
    editionNonce: number | null;
  }) {
    this.key = MetadataKey.MetadataV1;
    this.updateAuthority = args.updateAuthority;
    this.mint = args.mint;
    this.data = args.data;
    this.primarySaleHappened = args.primarySaleHappened;
    this.isMutable = args.isMutable;
    this.editionNonce = args.editionNonce ?? null;
  }
}

type Options = {
  /**
   * Wallet public address
   */
  publicAddress: string;
  program: anchor.Program;
  /**
   * Wallet public address
   */
  collectionMints: string[];
  /**
   * Optionally provide your own connection object.
   * Otherwise createConnectionConfig() will be used
   */
  connection?: Connection;
  stakingKey: string;
};

const getCollectionParsedNftAccountsByOwner = async ({
  publicAddress,
  program,
  collectionMints,
  connection,
  stakingKey,
}: Options) => {
  const isValidAddress = isValidSolanaAddress(publicAddress);
  if (!isValidAddress || !connection) {
    return [];
  }

  // Get all accounts owned by user
  // and created by SPL Token Program
  // this will include all NFTs, Coins, Tokens, etc.
  const { value: splAccounts } = await connection.getParsedTokenAccountsByOwner(
    new PublicKey(publicAddress),
    {
      programId: new PublicKey(TOKEN_PROGRAM_ID),
    }
  );

  // We assume NFT is SPL token with decimals === 0 and amount at least 1
  // At this point we filter out other SPL tokens, like coins e.g.
  // Unfortunately, this method will also remove NFTы created before Metaplex NFT Standard
  // like Solarians e.g., so you need to check wallets for them in separate call if you wish
  // We FILTER HERE by the NFT mint being part of the Collection to avoid fetching ALL NFTs
  const nftAccounts = splAccounts
    .filter((t) => {
      const amount = t.account?.data?.parsed?.info?.tokenAmount?.uiAmount;
      const decimals = t.account?.data?.parsed?.info?.tokenAmount?.decimals;
      const address = t.account?.data?.parsed?.info?.mint;
      return decimals === 0 && amount >= 1 && collectionMints.includes(address);
    })
    .map((t) => {
      const address = t.account?.data?.parsed?.info?.mint;
      return new PublicKey(address);
    });

  // Get all staked mints, to substract it from the wallet NFTs
  const stakedInProject = await fetchStakedAccounts(
    publicAddress,
    program,
    stakingKey
  );
  const stakedMintsInProject = stakedInProject.map((staked) => {
    return staked.account.mint.toString();
  });

  const nftAccountsNonStaked = nftAccounts.filter(
    (mint) => !stakedMintsInProject.includes(mint.toString())
  );

  // Get Addresses of Metadata Account assosiated with Mint Token
  // This info can be deterministically calculated by Associated Token Program
  // available in @solana/web3.js
  const metadataAcountsAddressPromises = await Promise.allSettled(
    nftAccountsNonStaked.map(getSolanaMetadataAddress)
  );

  const metadataAccounts = metadataAcountsAddressPromises
    .filter(onlySuccessfullPromises)
    .map((p) => (p as PromiseFulfilledResult<PublicKey>).value);

  // Fetch Found Metadata Account data by chunks
  const metaAccountsRawPromises: PromiseSettledResult<
    (AccountInfo<Buffer | ParsedAccountData> | null)[]
  >[] = await Promise.allSettled(
    chunk(metadataAccounts, 99).map((chunk) =>
      connection.getMultipleAccountsInfo(chunk as PublicKey[])
    )
  );

  const accountsRawMeta = metaAccountsRawPromises
    .filter(({ status }) => status === "fulfilled")
    .flatMap((p) => (p as PromiseFulfilledResult<unknown>).value);

  // There is no reason to continue processing
  // if Mints doesn't have associated metadata account. just return []
  if (!accountsRawMeta?.length || accountsRawMeta?.length === 0) {
    return [];
  }

  // Decode data from Buffer to readable objects
  const accountsDecodedMeta = await Promise.allSettled(
    accountsRawMeta.map((accountInfo) =>
      decodeTokenMetadata((accountInfo as AccountInfo<Buffer>)?.data)
    )
  );

  const accountsFiltered = accountsDecodedMeta
    .filter(onlySuccessfullPromises)
    .filter(onlyNftsWithMetadata)
    .map((p) => {
      const { value } = p as PromiseFulfilledResult<Metadata>;
      return sanitizeTokenMeta(value);
    })
    .map((token) => publicKeyToString(token));

  return accountsFiltered;
};

const sanitizeTokenMeta = (tokenData: Metadata) => ({
  ...tokenData,
  data: {
    ...tokenData?.data,
    name: sanitizeMetaStrings(tokenData?.data?.name),
    symbol: sanitizeMetaStrings(tokenData?.data?.symbol),
    uri: sanitizeMetaStrings(tokenData?.data?.uri),
  },
});

// Convert all PublicKey to string
const publicKeyToString = (tokenData: Metadata) => ({
  ...tokenData,
  mint: tokenData?.mint?.toString?.(),
  updateAuthority: tokenData?.updateAuthority?.toString?.(),
  data: {
    ...tokenData?.data,
    creators: tokenData?.data?.creators?.map((c: any) => ({
      ...c,
      address: new PublicKey(c?.address)?.toString?.(),
    })),
  },
});

export const sanitizeMetaStrings = (metaString: string) =>
  metaString.replace(/\0/g, "");

const onlySuccessfullPromises = (
  result: PromiseSettledResult<unknown>
): boolean => result && result.status === "fulfilled";

// Remove any NFT Metadata Account which doesn't have uri field
// We can assume such NFTs are broken or invalid.
const onlyNftsWithMetadata = (t: PromiseSettledResult<Metadata>) => {
  const uri = (
    t as PromiseFulfilledResult<Metadata>
  ).value.data?.uri?.replace?.(/\0/g, "");
  return uri !== "" && uri !== undefined;
};

export { fetchWalletNFTs };
