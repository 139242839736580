import config from "config";

const getProjectUrl = (slug: string, staked: boolean = false) => {
  const url = config.PROJECT_URL.replace("{slug}", slug);
  return staked ? url + "/staked" : url;
};

const getAppUrl = (path: string) => `${config.APP_URL}${path}`;

export { getProjectUrl, getAppUrl };
