import type {
  ProjectMetadata,
  MetadataFloppy,
  CardNFT,
  StakedNFT,
} from "types";
import { Connection, AccountInfo, PublicKey } from "@solana/web3.js";
import {
  getSolanaMetadataAddress,
  decodeTokenMetadata,
} from "@nfteyez/sol-rayz";
import { ApiService } from "services/ApiService";
import * as anchor from "@project-serum/anchor";
import { ProgramAccount } from "@project-serum/anchor";

const sanitizeMetaStrings = (metaString: string) =>
  metaString?.replace(/\0/g, "");

type ByKeys = {
  [key: string]: MetadataFloppy;
};

const chunk = (list: PublicKey[], size: number) =>
  [...Array(Math.ceil(list.length / size))].map((_) => list.splice(0, size));

const getTokensMetaplexMetadata = async (
  connection: Connection,
  stakedList: anchor.ProgramAccount[]
) => {
  const metadataAccounts = await Promise.all(
    stakedList.map((staked) => getSolanaMetadataAddress(staked.account.mint))
  );

  const chunkedAccounts = chunk(metadataAccounts, 100);

  const accountsInfoChunked = await Promise.all(
    chunkedAccounts.map(async (chunkArray) =>
      connection.getMultipleAccountsInfo(chunkArray)
    )
  );

  const rawAccountsInfo = accountsInfoChunked.reduce(
    (acc, arr) => [...acc, ...arr],
    []
  );

  const decodedMetadataInfo = await Promise.all(
    rawAccountsInfo.map((rawAccountInfo) => {
      return decodeTokenMetadata((rawAccountInfo as AccountInfo<Buffer>)?.data);
    })
  );

  return decodedMetadataInfo;
};

type FetchProjectStakedNFTsParams = {
  publicKey?: string;
  program?: anchor.Program;
  metadata: ProjectMetadata;
  connection: Connection;
  stakingKey: string;
};

const fetchStakedAccounts = async (
  publicKey: string,
  program: anchor.Program,
  stakingKey: string
): Promise<anchor.ProgramAccount[]> => {
  const accountFilter = {
    memcmp: {
      // anchor(8) + bumps(2) + key(32) + mint(32)
      offset: 74,
      bytes: publicKey,
    },
  };

  const staked = await program.account.stakedNft.all([accountFilter]);

  return staked.filter((staked) => {
    const key = staked.account.key as PublicKey;
    return stakingKey === key.toString();
  });
};

const fetchProjectStakedNFTs = async ({
  publicKey,
  program,
  metadata,
  connection,
  stakingKey,
}: FetchProjectStakedNFTsParams): Promise<CardNFT[]> => {
  if (!program || !publicKey) {
    return [];
  }

  const stakedInProject = await fetchStakedAccounts(
    publicKey,
    program,
    stakingKey
  );

  if (!stakedInProject?.length) {
    return [];
  }

  const nftsMetadata = metadata.reduce((map, item) => {
    map.set(item.mint, item);
    return map;
  }, new Map());

  const decodedMetadataInfo = await getTokensMetaplexMetadata(
    connection,
    stakedInProject
  );

  return stakedInProject.map((staked, index) => {
    const mint = staked.account.mint.toString();
    const metadataItem = nftsMetadata.get(mint);
    const tokenMetadata = decodedMetadataInfo.find(
      (obj) => obj.mint.toString() === mint
    );
    const name = tokenMetadata?.data?.name
      ? sanitizeMetaStrings(tokenMetadata?.data?.name)
      : "";
    const uri = tokenMetadata?.data?.uri
      ? sanitizeMetaStrings(tokenMetadata?.data?.uri)
      : "";

    return {
      mint,
      data: {
        name: name,
        uri: uri,
      },
      staked: true,
      rarityMultiplier: metadataItem?.rarityMultiplier,
    };
  });
};

type FetchAllStakedNFTsParams = {
  publicKey?: string;
  program?: anchor.Program;
  connection: Connection;
};

const fetchAllStakedNFTs = async ({
  publicKey,
  program,
  connection,
}: FetchAllStakedNFTsParams): Promise<StakedNFT[]> => {
  if (!program || !publicKey) {
    return [];
  }

  const accountFilter = {
    memcmp: {
      // anchor(8) + bumps(2) + key(32) + mint(32)
      offset: 74,
      bytes: publicKey,
    },
  };

  const staked = await program.account.stakedNft.all([accountFilter]);

  if (!staked?.length) {
    return [];
  }

  const apiService = new ApiService();

  const mints = staked.map((staked) => staked.account.mint as string);

  const stakedInfo = await apiService.getStakedInfo(mints);

  const nftsInfo = stakedInfo.data.reduce((map: any, item: any) => {
    // there may be orphan NFTs (without project in the database)
    if (item.Project) {
      map.set(item.mint, {
        rarityMultiplier: item.rarityMultiplier,
        project: { name: item.Project.name, slug: item.Project.slug },
      });
    }
    return map;
  }, new Map());

  const decodedMetadataInfo = await getTokensMetaplexMetadata(
    connection,
    staked
  );

  return staked.map((staked, index) => {
    const account: any = staked.account;
    const mint = account.mint.toString();
    const nftInfo = nftsInfo.get(mint);
    const tokenMetadata = decodedMetadataInfo.find(
      (obj) => obj.mint.toString() === mint
    );
    const stakingKey = account.key.toString();
    const lastClaim = account.lastClaim.toString();
    const stakedAt = account.stakedAt.toString();
    const name = tokenMetadata?.data?.name
      ? sanitizeMetaStrings(tokenMetadata?.data?.name)
      : "";
    const uri = tokenMetadata?.data?.uri
      ? sanitizeMetaStrings(tokenMetadata?.data?.uri)
      : "";

    return {
      mint,
      data: {
        name: name,
        uri: uri,
      },
      rarityMultiplier: nftInfo?.rarityMultiplier,
      stakingKey: stakingKey,
      lastClaim: lastClaim,
      stakedAt: stakedAt,
      project: nftInfo?.project,
    };
  });
};

export { fetchProjectStakedNFTs, fetchAllStakedNFTs, fetchStakedAccounts };
