import config from "config";
import { useMemo } from "react";
import { useAsync } from "react-use";
import { Metaplex } from "@metaplex-foundation/js";
import { getConnectionConfig } from "utils/getConnectionConfig";
import { PublicKey } from "@solana/web3.js";
import { Connection, clusterApiUrl, Keypair } from "@solana/web3.js";

const useTokenInfo = (mint: string | undefined) => {
  const connection = new Connection(
    config.SOLANA_ENDPOINT,
    getConnectionConfig()
  );

  const tokenData = useAsync(async () => {
    if (!mint) {
      return undefined;
    }

    // First, try to get metadata from token registry (old tokens, deprecated)
    const { TokenListProvider } = await import("@solana/spl-token-registry");

    const tokens = await new TokenListProvider().resolve();
    const tokenList = tokens
      .filterByClusterSlug(config.SOLANA_CLUSTER)
      .getList();

    const simpleTokenList = tokenList.reduce((map, item) => {
      map.set(item.address, item);
      return map;
    }, new Map());

    const tokenFromRegistry = simpleTokenList.get(mint);

    if (tokenFromRegistry) {
      return tokenFromRegistry;
    }

    // Then, use the new Metaplex token metadata, and return the same structure
    const metaplex = new Metaplex(connection);
    const token = await metaplex
      .nfts()
      .findByMint({ mintAddress: new PublicKey(mint) });

    return {
      name: token.name,
      symbol: token.symbol,
      logoURI: token.json?.image,
    };
  }, [mint]);

  return tokenData.value;
};

export default useTokenInfo;
