import { PublicKey } from "@solana/web3.js";
import { useWLProject } from "modules/WLProject";
import { useAsync } from "react-use";
import type { TokenInfo } from "@solana/spl-token-registry";
import { useTokenInfo } from "modules/useTokenInfo";

type Stats = {
  totalNfts: number;
  stakedNfts: number;
  rewardsBalance: number;
  rewardsAccount: string;
  tokenInfo: TokenInfo | undefined;
  dailyRewards: number;
  owner: PublicKey;
  feesExempt: boolean;
};

const hasError = (k: any) => k.error;

const useProjectStats = (): Stats | undefined => {
  const { stakingServiceInstance } = useWLProject();

  const staking = useAsync(async () => {
    if (!stakingServiceInstance) {
      return;
    }
    return stakingServiceInstance.getNftStakedTotal();
  }, []);

  const balanceResponse = useAsync(async () => {
    if (!stakingServiceInstance) {
      return;
    }
    return stakingServiceInstance.getRewardsAddressBalance();
  }, []);

  const ownerResponse = useAsync(async () => {
    if (!stakingServiceInstance) {
      return;
    }
    return stakingServiceInstance.getOwner();
  }, []);

  const stakingAccountResponse = useAsync(async () => {
    if (!stakingServiceInstance) {
      return;
    }
    return stakingServiceInstance.getStakingAccount();
  }, []);

  const tokenInfo = useTokenInfo(balanceResponse?.value?.mint);

  const statsItems = [staking, balanceResponse, ownerResponse];

  if (statsItems.some(hasError)) {
    console.error(balanceResponse.error);
  }

  if (
    typeof staking.value === "undefined" ||
    typeof balanceResponse.value === "undefined"
  ) {
    return;
  }

  if (typeof stakingAccountResponse.value === "undefined") {
    return;
  }

  if (typeof ownerResponse.value === "undefined") {
    return;
  }

  const { total, staked } = staking.value;
  const rewardsBalance = balanceResponse.value.balance;
  const owner = ownerResponse.value.owner;
  const feesExempt = stakingAccountResponse.value.feesExempt;

  return {
    totalNfts: total,
    stakedNfts: staked,
    rewardsBalance,
    rewardsAccount: balanceResponse.value.account,
    tokenInfo,
    dailyRewards: balanceResponse.value.dailyRewards,
    owner: owner,
    feesExempt: feesExempt,
  };
};

export default useProjectStats;
