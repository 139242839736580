import { AppProps } from "next/app";
import { WalletProvider } from "modules/WalletProvider";
import { NextSeo } from "next-seo";
import config from "config";

const ProfilePageProvider = ({ Component, pageProps }: AppProps) => {
  return (
    <>
      <NextSeo
        title="My Staking - FloppyLabs"
        description="My Staking - FloppyLabs staker-centric page, where all Staked NFTs are shown grouped by collection"
        openGraph={{
          type: "website",
          images: [
            {
              url: `${config.HOME_URL}/open-graph.png`,
              width: 1200,
              height: 628,
              alt: "My Staking",
              type: "image/png",
            },
          ],
        }}
        twitter={{
          // TODO replace this with the project Twitter account.
          site: "@FloppyLabs",
        }}
      />
      <WalletProvider>
        <Component {...pageProps} />
      </WalletProvider>
    </>
  );
};

export default ProfilePageProvider;
