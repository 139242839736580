import "styles/globals.css";
import type { AppProps } from "next/app";
import { AnimatePresence } from "framer-motion";
import { WLPageProvider } from "modules/WLProject";
import { SubmitPageProvider } from "modules/SubmitPage";
import { ProfilePageProvider } from "modules/Profile";
import { Toaster } from "react-hot-toast";
import { DefaultSeo } from "next-seo";
import config from "config";
import Script from "next/script";
import { AppLayout } from "types";
import { useAsync } from "react-use";
import { ApiServiceInstance } from "services/ApiService";

const DecideProvider = (props: AppProps) => {
  const { Component, pageProps } = props;

  if (pageProps?.appLayout === AppLayout.PROJECT_PAGE) {
    return <WLPageProvider {...props} />;
  }

  if (pageProps?.appLayout === AppLayout.PROFILE_PAGE) {
    return <ProfilePageProvider {...props} />;
  }

  if (pageProps?.appLayout === AppLayout.SUBMIT_PAGE) {
    return <SubmitPageProvider {...props} />;
  }

  return <Component {...pageProps} />;
};

function MyApp(props: AppProps) {
  return (
    <>
      <DefaultSeo
        title="Three words, Solana NFT staking. One price, free."
        description="Have 10 minutes? That is about all you will need to get a fully functioning staking website up & running for your project."
        canonical="https://floppylabs.io/"
        openGraph={{
          type: "website",
          images: [
            {
              url: `${config.HOME_URL}/open-graph.png`,
              width: 1200,
              height: 628,
              alt: "FloppyLabs",
              type: "image/png",
            },
          ],
        }}
        twitter={{
          site: "@FloppyLabs",
        }}
      />
      <Toaster
        containerStyle={{
          top: 20,
          left: 20,
          bottom: 20,
          right: 20,
        }}
      />
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}');
        `}
      </Script>
      <AnimatePresence
        exitBeforeEnter
        initial={false}
        onExitComplete={() => window.scrollTo(0, 0)}
      >
        <DecideProvider {...props} />
      </AnimatePresence>
    </>
  );
}

export default MyApp;
