import { useMemo } from "react";
import { useAsync, useAsyncFn } from "react-use";
import * as anchor from "@project-serum/anchor";
import config from "config";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { useProgram } from "modules/useProgram";
import { fetchAllStakedNFTs } from "services/fetchStakedNFTs";
import { getConnectionConfig } from "utils/getConnectionConfig";
import { ApiService } from "services/ApiService";

const useProfile = () => {
  const connection = useMemo(
    () =>
      new anchor.web3.Connection(config.SOLANA_ENDPOINT, getConnectionConfig()),
    []
  );
  const wallet = useAnchorWallet();
  const publicKey = wallet?.publicKey?.toString();

  const { program } = useProgram({ connection, wallet });

  const [stakedState, _fetchStaked] = useAsyncFn(async () => {
    return fetchAllStakedNFTs({
      publicKey,
      connection,
      program,
    });
  }, [publicKey, program]);

  const stakedNFTs = stakedState.value || [];
  const isLoadingStaked = stakedState.loading;
  const errorStaked = stakedState.error;

  const apiService = new ApiService();

  // Get total staked in the platform
  const totalStakedFunction = useAsync(async () => {
    return await apiService.getTotalStaked();
  }, [wallet]);

  const totalStakedInfo = totalStakedFunction?.value?.data;

  // Fetch staked NFTs
  useAsync(async () => {
    await _fetchStaked();
  }, [_fetchStaked]);

  return {
    stakedNFTs,
    isLoadingStaked,
    errorStaked,
    publicKey,
    totalStakedInfo,
  };
};

export default useProfile;
