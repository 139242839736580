import { useAsync } from "react-use";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { ApiService } from "services/ApiService";

const useDiscordUser = () => {
  const wallet = useAnchorWallet();
  const publicKey = wallet?.publicKey?.toString();

  const apiService = new ApiService();

  // Try to get verified staker discord info
  const stakerDiscordUserFunction: any = useAsync(async () => {
    return publicKey
      ? await apiService.getStakerDiscordUser(publicKey)
      : undefined;
  }, [publicKey]);

  const stakerDiscordUserRegistered = stakerDiscordUserFunction?.value?.data; // true / false

  return {
    stakerDiscordUserRegistered,
    stakerDiscordUserFunction,
  };
};

export default useDiscordUser;
