import React from "react";

import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { FaDiscord, FaTwitter } from "react-icons/fa";

type Props = {};

const WalletStep: React.FC<Props> = () => {
  return (
    <div className="flex flex-1 items-center">
      <div className="rounded-box mx-auto max-w-2xl bg-neutral pb-12">
        <div className="mx-4 flex flex-col items-center">
          <div className="mx-auto mb-8 max-w-2xl text-center">
            <h2 className="mt-12 text-2xl">
              Kickstart your staking project by connecting your wallet
            </h2>
            <div className="mt-4 text-gray-400">
              This wallet is the admin wallet for the staking project. You will
              be able to adjust the configuration after its creation. You will
              have access to many actions, such as withdrawing rewards from the
              rewards account.
            </div>
            <div className="mt-4 mt-8 text-gray-400">
              Are you running into trouble or have any questions, please join us
              in{" "}
              <a
                href="https://discord.gg/vPGn2vfXJt"
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                <FaDiscord className="inline pr-1" size="1.4em" />
                Discord
              </a>{" "}
              and let us know!
            </div>
          </div>
          <div className="mt-8">
            <WalletMultiButton className="btn !btn-primary" />
          </div>
        </div>
      </div>
    </div>
  );
};

export { WalletStep };
