import { GlobalState } from "little-state-machine";
import { ConfirmationData, FormProjectData } from "types";

const updateProjectData = (
  state: GlobalState,
  payload: FormProjectData
): GlobalState => {
  const mintList = JSON.parse(payload.hashList);

  return {
    ...state,
    submitPageFormRarityData: {
      mintList,
    },
  };
};

const setSubmitPageFormProjectData = (
  state: GlobalState,
  payload: FormProjectData
): GlobalState => {
  return {
    ...state,
    submitPageFormProjectData: { ...payload },
  };
};

const setConfirmationData = (
  state: GlobalState,
  payload: ConfirmationData
): GlobalState => {
  return {
    ...state,
    submitPageConfirmationData: { ...payload },
  };
};

export { updateProjectData, setSubmitPageFormProjectData, setConfirmationData };
