import { AppProps } from "next/app";
import { WalletProvider } from "modules/WalletProvider";
import { SubmitPageDataProvider } from "./SubmitPageDataProvider";

const SubmitPageProvider = ({ Component, pageProps }: AppProps) => {
  return (
    <WalletProvider>
      <SubmitPageDataProvider>
        <Component {...pageProps} />
      </SubmitPageDataProvider>
    </WalletProvider>
  );
};

export default SubmitPageProvider;
