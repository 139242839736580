import config from "config";
import { Cluster, resolveClusterFromEndpoint } from "@metaplex-foundation/js";

const getMetaplexCluster = (): Cluster => {
  let metaplexClusterResolverUrl;
  switch (config.SOLANA_CLUSTER) {
    case "mainnet-beta":
      metaplexClusterResolverUrl = "https://api.mainnet-beta.solana.com";
      break;
    case "devnet":
      metaplexClusterResolverUrl = "https://api.devnet.solana.com";
      break;
    case "localhost":
      metaplexClusterResolverUrl = "http://localhost";
      break;
    default:
      metaplexClusterResolverUrl = "https://api.mainnet-beta.solana.com";
  }
  return resolveClusterFromEndpoint(metaplexClusterResolverUrl);
};

export { getMetaplexCluster };
