import { SendTransactionError } from "@solana/web3.js";

const parseSendTransactionError = (err: Error): string => {
  let message;
  console.log(err);

  if (err instanceof SendTransactionError) {
    if (err.message.search("0x11") > -1) {
      message = "NFT is frozen";
    } else if (err.message.search("0x1") > -1) {
      message = "insufficient funds";
    } else if (err.message.search("0xbc4") > -1) {
      message = "out of sync. Refresh page";
    } else if (err.message.search("0x1770") > -1) {
      message = "staking not started yet";
    } else {
      message = err.message;
    }
  } else {
    message = err.message;
  }

  return message;
};

export { parseSendTransactionError };
