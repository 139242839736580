import React, { ReactNode } from "react";
import { motion } from "framer-motion";
import Image from "next/image";
import { useProjectStats } from "modules/WLProject/hooks";
import { useWLProject } from "modules/WLProject";
import { useDiscordUser } from "modules/DiscordUser";
import { getExplorerURL } from "utils/getExplorerURL";
import { getImageSrc } from "utils/getImageSrc";
import { useRouter } from "next/router";
import logo from "/public/logo-loading.png";
import { NavBar } from "components/NavBar";
import { AdminView } from "components/AdminView";
import Link from "next/link";
import { getProjectUrl } from "utils/urls";

type Props = {
  projectName: string;
  projectSlug: string;
  projectDescription: string;
  projectImageUrl: string;
  children: ReactNode;
  menuComponent: ReactNode;
  customStyle: any;
};

const variants = {
  hidden: { opacity: 0, x: 0, y: 0 },
  enter: { opacity: 1, x: 0, y: 0 },
  exit: { opacity: 0, x: 0, y: 0 },
};

const formatter = Intl.NumberFormat("en", { notation: "compact" });

const ProjectLayout = ({
  children,
  projectName,
  projectDescription,
  projectImageUrl,
  menuComponent,
  customStyle,
}: Props): JSX.Element => {
  const router = useRouter();
  const stats = useProjectStats();
  const { publicKey } = useWLProject();
  const { stakerDiscordUserRegistered, stakerDiscordUserFunction } =
    useDiscordUser();

  if (router.isFallback) {
    return (
      <div className="absolute top-0 left-0 right-0 bottom-0 flex flex-col items-center justify-center">
        <div className="animate-bounce">
          <Image src={logo} alt="Floppy loading..." width={48} height={48} />
        </div>
        <div>Floppy loading...</div>
      </div>
    );
  }

  return (
    <div className="main-container absolute top-0 left-0 right-0 bottom-0 flex flex-col">
      <style global jsx>
        {customStyle}
      </style>
      <NavBar showProfileButton={!!publicKey} />
      <div className="info-boxes grid grid-cols-1 gap-4 px-4 md:grid-cols-2">
        <div className="flex flex-col items-center rounded-lg bg-neutral p-4 shadow-lg md:flex-row">
          {!!projectImageUrl && (
            <div className="avatar md:mr-6">
              <div className="mask mask-hexagon w-28">
                <img
                  src={projectImageUrl}
                  width={250}
                  height={250}
                  alt={projectName}
                />
              </div>
            </div>
          )}
          <div className="mt-4 text-center md:mt-0 md:text-left">
            <h1 className="mb-4 text-4xl font-bold">{projectName}</h1>
            <div>{projectDescription}</div>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <div className="stat rounded-lg bg-neutral p-4 shadow-lg">
            <div className="opacity-1 stat-title">NFTs staked</div>
            {stats?.stakedNfts !== undefined && stats?.totalNfts !== undefined && (
              <>
                <div className="stat-value">
                  {stats?.stakedNfts}{" "}
                  <span className="font-normal text-gray-500">/</span>{" "}
                  {stats?.totalNfts}
                </div>
                <div className="stat-desc">
                  {((stats.stakedNfts * 100) / stats?.totalNfts).toFixed(2)}% of
                  the total
                </div>
              </>
            )}
          </div>
          <div className="stat overflow-hidden rounded-lg bg-neutral p-4 shadow-lg">
            <div className="flex">
              {!!stats?.tokenInfo?.logoURI && (
                <div className="relative mr-2 h-[24px] w-[24px]">
                  <Image
                    src={getImageSrc(stats?.tokenInfo?.logoURI)}
                    layout="fill"
                    alt={stats?.tokenInfo?.name}
                  />
                </div>
              )}
              <div>Daily rewards / NFT</div>
            </div>
            <div className="stat-value overflow-hidden text-ellipsis whitespace-nowrap">
              {stats?.rewardsBalance !== undefined &&
                formatter.format(stats?.dailyRewards)}
              {!!stats?.tokenInfo && (
                <span className="text-base font-normal">
                  {" "}
                  ${stats?.tokenInfo?.symbol}
                </span>
              )}
            </div>
            {typeof stats?.rewardsBalance !== "undefined" && (
              <div className="stat-desc">
                <a
                  href={getExplorerURL(stats.rewardsAccount)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {formatter.format(stats?.rewardsBalance)} in rewards wallet
                  &#10697;
                </a>
              </div>
            )}
          </div>
        </div>
      </div>

      {!!publicKey?.toString() &&
        !!stats?.owner?.toString() &&
        publicKey.toString() === stats.owner.toString() && (
          <div className="mt-4 w-full px-4">
            <AdminView />
          </div>
        )}

      {menuComponent}

      <div className="mt-4 flex flex-1 flex-col px-4 pb-12 ">
        {!!publicKey &&
          !stats?.feesExempt &&
          !stakerDiscordUserRegistered &&
          !stakerDiscordUserFunction.loading && (
            <div className="alert alert-warning mb-4 rounded-lg p-3 shadow-lg">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  className="h-6 w-6 flex-shrink-0 stroke-black"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>
                <div>
                  <h3 className="font-bold">
                    Get your verified <b>Staker role</b>!
                  </h3>
                  <div className="text-xs">
                    Verify your Discord to access <b>exclusive benefits</b> at
                    FloppyLabs server: giveaways, WL spots, alpha channel...{" "}
                  </div>
                </div>
              </div>
              <div className="flex-none">
                <Link href={getProjectUrl("verify")}>
                  <button className="btn ">Verify as staker</button>
                </Link>
              </div>
            </div>
          )}

        <motion.main
          initial="hidden"
          animate="enter"
          exit="exit"
          variants={variants}
          transition={{ type: "linear" }}
          className="flex-1"
        >
          {children}
        </motion.main>
      </div>
    </div>
  );
};

export default ProjectLayout;
