import { createContext, FC, useCallback, useContext, useMemo } from "react";
import { FormRarityData, ConfirmationData, FormProjectData } from "types";
import { AnchorWallet, useAnchorWallet } from "@solana/wallet-adapter-react";
import { useProgram } from "modules/useProgram";
import * as anchor from "@project-serum/anchor";
import config from "config";
import { Connection } from "@solana/web3.js";
import {
  createStore,
  StateMachineProvider,
  useStateMachine,
} from "little-state-machine";
import { StakingProjectCreator } from "services/StakingProjectCreator";
import {
  setConfirmationData,
  setSubmitPageFormProjectData,
  updateProjectData,
} from "modules/SubmitPage/actions";
import * as Sentry from "@sentry/nextjs";
import { getConnectionConfig } from "utils/getConnectionConfig";

const connection = new anchor.web3.Connection(
  config.SOLANA_ENDPOINT,
  getConnectionConfig()
);

type SubmitPageData = {
  wallet?: AnchorWallet;
  program?: anchor.Program;
  connection: Connection;
  createProject: (data: FormProjectData) => Promise<void>;

  submitPageFormProjectData: FormProjectData;
  submitPageFormRarityData: FormRarityData;
  submitPageConfirmationData: ConfirmationData | undefined;

  submitProjectInfo: (data: FormProjectData) => Promise<any>;
  persistProjectInfo: (data: FormProjectData) => Promise<any>;
};

const SubmitPageDataContext = createContext<SubmitPageData>(
  {} as SubmitPageData
);

const submitPageFormProjectDataDefault: FormProjectData = {
  projectName: "",
  projectSlug: "",
  projectDescription: "",
  imageUrl: "",
  tokenMintAddress: "",
  dailyRewards: "",
  goLiveDate: "",
  hashList: "",
};

const submitPageFormRarityDataDefault: FormRarityData = {
  mintList: [],
};

const submitPageConfirmationDataDefault = undefined;

createStore(
  {
    submitPageFormProjectData: submitPageFormProjectDataDefault,
    submitPageFormRarityData: submitPageFormRarityDataDefault,
    submitPageConfirmationData: submitPageConfirmationDataDefault,
  },
  { name: "FloppyLabs" }
);

const SubmitPageDataProviderStateLess: FC = ({ children }) => {
  const wallet = useAnchorWallet();
  const { program } = useProgram({ connection, wallet });

  const { state, actions } = useStateMachine({
    updateProjectData,
    setSubmitPageFormProjectData,
    setConfirmationData,
  });

  const projectCreator = useMemo(
    () =>
      new StakingProjectCreator({
        program,
        wallet,
        connection,
      }),
    [program, wallet]
  );

  const createProject = async (data: FormProjectData) => {
    try {
      projectCreator.setCreationData({
        projectName: data.projectName,
        projectSlug: data.projectSlug,
        projectDescription: data.projectDescription,
        imageUrl: data.imageUrl,
        tokenMintAddress: data.tokenMintAddress,
        dailyRewards: parseFloat(data.dailyRewards),
        goLiveDate: data.goLiveDate,
      });

      const mints = JSON.parse(data.hashList);

      projectCreator.setMetadata(
        mints.map((mint: any) => ({
          mint: mint,
          rarityMultiplier: 100,
        }))
      );

      const confirmationData = await projectCreator.prepare().create();

      actions.setConfirmationData(confirmationData);
    } catch (err: any) {
      console.error(err);
      Sentry.withScope((scope) => {
        scope.setExtra("action", "CreateProject");
        scope.setExtra("request", data);
        scope.setExtra("response", err?.response?.data);
        scope.setTags(projectCreator.getDebugInfo());
        Sentry.captureException(err);
      });
      throw new Error("Error creating project", err);
    }
  };

  const submitProjectInfo = useCallback(
    async (data) => {
      actions.updateProjectData(data);
    },
    [actions, wallet]
  );

  const persistProjectInfo = useCallback(
    async (data) => {
      actions.setSubmitPageFormProjectData(data);
    },
    [actions]
  );

  const {
    submitPageFormProjectData,
    submitPageFormRarityData,
    submitPageConfirmationData,
  } = state;

  const value = {
    wallet,
    program,
    connection,
    createProject,
    submitPageConfirmationData,
    submitPageFormProjectData,
    submitPageFormRarityData,
    submitProjectInfo,
    persistProjectInfo,
  };

  return (
    <SubmitPageDataContext.Provider value={value}>
      {children}
    </SubmitPageDataContext.Provider>
  );
};

const SubmitPageDataProvider: FC = (props) => (
  <StateMachineProvider>
    <SubmitPageDataProviderStateLess {...props} />
  </StateMachineProvider>
);

const useSubmitPageData = () => useContext(SubmitPageDataContext);

export { SubmitPageDataProvider, useSubmitPageData };
