type WLStakingConfig = {
  SOLANA_ENDPOINT: string;
  STAKING_PROGRAM: string;
  FEES_ACCOUNT: string;
  HOME_URL: string;
  APP_URL: string;
  PROJECT_URL: string;
  SOLANA_CLUSTER: string;
};

const config: WLStakingConfig = {
  SOLANA_ENDPOINT: process.env.NEXT_PUBLIC_SOLANA_ENDPOINT as string,
  STAKING_PROGRAM: process.env.NEXT_PUBLIC_STAKING_PROGRAM as string,
  FEES_ACCOUNT: process.env.NEXT_PUBLIC_FEES_ACCOUNT as string,
  HOME_URL: process.env.NEXT_PUBLIC_HOME_URL as string,
  APP_URL: process.env.NEXT_PUBLIC_APP_URL as string,
  PROJECT_URL: process.env.NEXT_PUBLIC_PROJECT_URL as string,
  SOLANA_CLUSTER: process.env.NEXT_PUBLIC_SOLANA_CLUSTER as string,
};

export default config;
