import { FC } from "react";
import cx from "classnames";
import { useRouter } from "next/router";

type Props = {
  basePath?: string;
};

const basePathPrefix = (basePath: string, step: string) => `${basePath}${step}`;

const Stepper: FC<Props> = ({ basePath = "/submit" }) => {
  const router = useRouter();

  const isSelected = (paths: string[]) =>
    paths.map((path) => basePathPrefix(basePath, path)).includes(router?.asPath)
      ? "step-primary"
      : false;

  return (
    <ul className="steps steps-horizontal my-6 w-full md:my-12">
      <li className={cx("step", isSelected(["", "/rarity", "/confirmation"]))}>
        Project Info
      </li>
      <li className={cx("step", isSelected(["/confirmation"]))}>
        Confirmation
      </li>
    </ul>
  );
};

export { Stepper };
