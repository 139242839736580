import { createContext } from "react";
import { PublicKey } from "@solana/web3.js";
import { WLStakingService } from "services/WLStakingService";

const defaultValue = {
  reFetch: () => {
    //noop
  },
  stakeAll: () => {
    //noop
  },
  unstakeAll: () => {
    //noop
  },
  claimAll: () => {
    //noop
  },
  updateProject: () => {
    //noop
  },
  syncNativeRewards: () => {
    //noop
  },
  withdrawAllRewards: () => {
    //noop
  },
  updateHashlist: () => {
    //noop
  },
  stakingKey: "",
  slug: "",
  stakingAll: false,
  unstakingAll: false,
  claimingAll: false,
  updatingProject: false,
  syncingNativeRewards: false,
  withdrawingAllRewards: false,
  updatingHashlist: false,
};

type Props = {
  metadata?: any;
  stakingKey: string;
  slug: string;

  walletNFTs?: any[];
  isLoadingWallet?: boolean;
  errorWallet?: any;

  stackedNFTs?: any[];
  isLoadingStaked?: boolean;
  errorStaked?: any;

  publicKey?: PublicKey;

  reFetch: Function;

  stakingServiceInstance?: WLStakingService;

  stakeAll: (e: any) => void;
  stakingAll: boolean;

  unstakeAll: (e: any) => void;
  unstakingAll: boolean;

  claimAll: (e: any) => void;
  claimingAll: boolean;

  updateProject: (
    newDailyRewards: number,
    newOwner: string,
    newMints: string[]
  ) => void;
  updatingProject: boolean;

  syncNativeRewards: () => void;
  syncingNativeRewards: boolean;

  withdrawAllRewards: () => void;
  withdrawingAllRewards: boolean;

  updateHashlist: (newHashList: any) => void;
  updatingHashlist: boolean;
};

const WLContext = createContext<Props>(defaultValue);

export default WLContext;
