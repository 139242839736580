import React from "react";
import dynamic from "next/dynamic";
import { ConnectionProvider } from "@solana/wallet-adapter-react";
import { getConnectionConfig } from "utils/getConnectionConfig";
import config from "config";

type Props = {};

const ClientWalletProvider = dynamic(() => import("./ClientWalletProvider"), {
  ssr: false,
});

const WalletProvider: React.FC<Props> = ({ children }) => (
  <ConnectionProvider
    endpoint={config.SOLANA_ENDPOINT}
    config={getConnectionConfig()}
  >
    <ClientWalletProvider>{children}</ClientWalletProvider>
  </ConnectionProvider>
);

export default WalletProvider;
