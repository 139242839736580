import toast from "react-hot-toast";
import { getExplorerURL } from "utils/getExplorerURL";

class TimeoutToast {
  timeout: number;
  toastId: string | undefined;
  timeoutId: NodeJS.Timeout | undefined;

  constructor(props?: { timeout: number }) {
    const { timeout = 30_000 } = props || {};
    this.timeout = timeout;
  }

  prepare(signatures: string[] | undefined) {
    this.timeoutId = setTimeout(() => {
      this.toastId = toast.loading(
        () => (
          <div>
            <div className="mb-2">This is taking longer than expected</div>
            {!!signatures &&
              signatures.map((signature, index) => (
                <p key={index}>
                  <a
                    className="link-neutral font-bold underline"
                    href={getExplorerURL(signature, "tx")}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View transaction {signatures.length > 1 ? index : ""} on
                    Solscan
                  </a>
                </p>
              ))}
          </div>
        ),
        {
          position: "bottom-center",
        }
      );
    }, this.timeout);
  }

  dismiss() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    if (this.toastId) {
      toast.dismiss(this.toastId);
    }
  }
}

export { TimeoutToast };
